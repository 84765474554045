import request from "@/utils/request";

export function getNews(params) {
  return request({
    url: "/news",
    method: "get",
    params: {
      filter: params,
    },
  });
}

export function getNewsCount(params) {
  return request({
    url: "/news/count",
    method: "get",
    where: params
  });
}
