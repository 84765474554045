<template>
  <div class="news-box">
    <el-container style="margin-top: 24px">
      <!-- 侧边 -->
      <!-- <el-aside width="330px">
        <div>
          <newsTrends :modelName="'news'" :limit="5" :isHidden="true" />
        </div>
      </el-aside> -->

      <!-- 中间 -->
      <el-main class="main">
        <div
          style="
            width: 100%;
            height: 1px;
            background: #afafaf;
            position: relative;
            top: 35px;
          "
        ></div>
        <div style="display: flex; align-items: center">
          <div style="height: 34px; width: 18px">
            <div
              style="
                height: 25px;
                width: 18px;
                background-color: rgba(65, 144, 200, 1);
              "
            ></div>
            <div
              style="
                height: 9px;
                width: 18px;
                background-color: rgba(89, 194, 224, 1);
              "
            ></div>
          </div>
          <span style="margin-left: 10px; font-weight: 550"
            >理事长、秘书长邮箱</span
          >
          <span
            style="
              margin-left: 12px;
              color: #e5e5e5;
              font-size: 12px;
              line-height: 26px;
            "
            >Email of Secretary and Dean</span
          >
        </div>

        <div>
          <!-- <div v-html="this.newsData.content"></div> -->

          <div
            style="
              display: flex;
              width: 100%;
              color: #4190c8;
              font-size: 16px;
              justify-content: space-between;
              margin-top: 40px;
            "
          >
            <div style="display: flex; width: 45%; align-items: center">
              <div
                style="
                  display: flex;
                  height: 18px;
                  flex-direction: column;
                  justify-content: space-between;
                  padding-right: 3%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                </div>
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                </div>
              </div>
              <div>理事长邮箱: {{ newsData.secretaryEmail }}</div>
            </div>
            <div style="display: flex; width: 45%; align-items: center">
              <div
                style="
                  display: flex;
                  height: 18px;
                  flex-direction: column;
                  justify-content: space-between;
                  padding-right: 3%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                </div>
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                </div>
              </div>
              <div>秘书长邮箱： {{ newsData.presidentEmail }}</div>
            </div>
          </div>

          <!-- <div
            style="
              display: flex;
              width: 100%;
              color: #4190c8;
              font-size: 16px;
              justify-content: space-between;
              padding-top: 5%;
            "
          >
            <div style="display: flex; width: 45%; align-items: center">
              <div
                style="
                  display: flex;
                  height: 18px;
                  flex-direction: column;
                  justify-content: space-between;
                  padding-right: 3%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                </div>
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                </div>
              </div>
              <div>地址： {{ newsData.address }}</div>
            </div>
          </div> -->
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getLeaderEmail } from '@/api/contacts'
import newsTrends from '@/view/components/news'
export default {
  name: 'Institutearticles',
  components: {
    newsTrends
  },
  data() {
    return {
      newsData: []
    }
  },
  methods: {
    getData() {
      getLeaderEmail()
        .then((res) => {
          this.newsData = res.data[0]
        })
        .catch((err) => {})
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.news-box {
  height: auto;

  .topTitle {
    position: relative;
    width: 280px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .news-sid {
    margin-top: 1px;
    position: relative;
    width: 281px;
    height: 50px;
    line-height: 50px;
    background-color: #ebf4fb;
  }

  v .main {
    height: auto;
    // height: 100%;
  }
}
</style>
