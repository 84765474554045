<template>
	<div class="row justify-between shadow-bottom bg-white w-100 h-100 text-noselect ">
		

		<!-- 历史记录弹出 -->
		<el-dialog title="历史记录" :visible.sync="dialogHistory" width="98%" class="el_dialog el_dialog1"
			:close-on-click-modal="false">
			<div class="el_dialog_daohang">
				<div class="guide" style="width: 100%;">
					<ul class="el_dialog_head_left ">
						<li @click="tabChange(5)" :class="{ active: tabHistory == 5 }" data-id="5"
							style="font-size: 24px;">
							历史记录
						</li>
						<!-- <li @click="tabChange(6)" :class="{ active: tabHistory == 6 }" data-id="6"
							style="font-size: 24px;">老年人中医保健</li>
						<li @click="tabChange(7)" :class="{ active: tabHistory == 7 }" data-id="7"
							style="font-size: 24px;">认知调查</li>
						<li @click="tabChange(8)" :class="{ active: tabHistory == 8 }" data-id="8"
							style="font-size: 24px;">抑郁调查</li> -->
					</ul>
				</div>
				<div class="el_dialog_subject">
					<!-- 老年人自理能力评估 -->
					<div class="el_dialog_text ">
						<div>
							<!-- 搜索条件 -->
							<div>
								<span class="id f-s-32">
									体检编号
									<input type="text" v-model="search_zlnlpg_id" maxlength="13"
										style="font-size: 24px;" />
								</span>
								<span class="date f-s-32">
									评估日期
									<el-date-picker v-model="search_zlnlpg_pgrq" type="daterange" range-separator="-"
										start-placeholder="开始日期" end-placeholder="结束日期">
									</el-date-picker>
								</span>
								<span class="el_dia_bu">
									<button class="el_dia_button" @click="getCount">
										<i class="el-icon-search"></i>
										搜索
									</button>
								</span>
							</div>
							<!-- 列表 -->
							<div class="el_dialog_el-table" style="margin-top: 40px;">
								<el-table ref="multipleTable" :data="lnrzlnlList" height="440" border
									tooltip-effect="dark" style="width: 100%;font-size: 22px;"
									v-loading="loadingHistory">
									<el-table-column align="center" label="体检编号" width="280">
										<template slot-scope="{ row }">
											<span>{{ row.serialId }}</span>
										</template>
									</el-table-column>
									<el-table-column align="center" label="姓名" width="150">
										<template slot-scope="{ row }">
											<span>{{ row.name }}</span>
										</template>
									</el-table-column>
									<el-table-column align="center" label="性别" width="140">
										<template slot-scope="{ row }">
											<span>{{ row.sex }}</span>
										</template>
									</el-table-column>
									<el-table-column align="center" label="年龄" width="140">
										<template slot-scope="{ row }">
											<span>{{ row.age }}</span>
										</template>
									</el-table-column>
									<el-table-column align="center" label="随访医生" width="260">
										<template slot-scope="{ row }">
											<span>{{ row.doctor_name }}</span>
										</template>
									</el-table-column>
									<el-table-column align="center" label="评估日期">
										<template slot-scope="{ row }">
											<span>{{ $moment(row.updatedAt).format('YYYY-MM-DD HH:MM') }}</span>
										</template>
									</el-table-column>

									<el-table-column align="center" label="操作" width="240">
										<template slot-scope="{ row }">
											<el-button type="primary" size="mini" @click="viewRecords(row, 1)">查看
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<!-- 分页 -->
							<div class="block mt-8">
								<el-pagination @size-change="handleSizeChange($event, 1)"
									@current-change="handleCurrentChange($event, 1)" :current-page="currentPage"
									:page-sizes="[10, 15, 20]" :page-size="pageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="total_zlnlpg">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex';
	export default {
		name: 'headBar',
		data() {
			return {
				msg: '12333333',
				tabHistory: 5,
				tzArray: tzArray.tzArray,
				search_zlnlpg_id: '', //id
				search_zlnlpg_pgrq: [], //时间
				search_zlnlpg_syn: '', //同步
				search_zlnlpg_photo: false, //照片
				search_zybj_tzlx: '', //体质

				dialogHistory: false, //历史记录弹窗
				dialogDataUp: false, //数据上传
				loadingHistory: false, //加载状态
				lnrzlnlList: [],
				lnrzybjList: [],
				lnrrzdcList: [],
				lnryydcList: [],
				pageSize: 10, //每页多少条
				currentPage: 1, //第几页
				total_zlnlpg: 10, //数据总数

				returnMessage: '',
				percentage: 0,
				tabDataUp: 0,
				serialId: '', //体检编号
				userinfo: {},
				sexOption: [{
						value: '1',
						label: '男',
					},
					{
						value: '0',
						label: '女',
					},
				],
				OldElevateMyself: 0, //自理能力
				OldMedicalCognitivesurvey: 0, //认知调查
				OldMedicalDepressed: 0, //抑郁调查
				OldMedicalHealthcare: 0, //中医保健,
				updataLoading: false,
				upDataLoad: false,
				upDataMessage: {
					OldElevateMyself: 0,
					OldMedicalCognitivesurvey: 0,
					OldMedicalHealthcare: 0,
					OldMedicalDepressed: 0,
					sussess: 0,
					total: 0,
				},
				connectionStatus: false,
			};
		},
		props: {},
		watch: {
			serialId(value) {
				if (value.length == 13) {
					this.$emit('inputChange', value);
				} else if (value.length == 0) {
					this.$router.go(0);
				}
			},
		},
		mounted() {
			// this.$refs.serialid.focus();
		},
		created() {
			// this.getConnectionStatus();
		},
		methods: {
			...mapMutations(['setValue']),
			getConnectionStatus() {
				setTimeout(() => {
					this.getConnectionStatus();
				}, 10000);

				getConnectionStatus()
					.then((res) => {
						this.connectionStatus = true;
					})
					.catch((err) => {
						this.connectionStatus = false;
					});
			},

			sexChange() {
				setTimeout(() => {
					this.storeSync();
				}, 100);
			},
			inputChange() {
				setTimeout(() => {
					this.storeSync();
				}, 100);
			},
			dataUpdate() {
				this.dialogDataUp = true;
				this.OldElevateMyself = 0;
				this.OldMedicalCognitivesurvey = 0;
				this.OldMedicalHealthcare = 0;
				this.OldMedicalDepressed = 0;
				this.upDataMessage = {
					OldElevateMyself: 0,
					OldMedicalCognitivesurvey: 0,
					OldMedicalHealthcare: 0,
					OldMedicalDepressed: 0,
					sussess: 0,
					total: 0,
				};
			},
			storeSync() {
				this.setValue('userinfo', this.userinfo);
			},
			userinfoChange() {
				this.userinfo = this.$store.state.userinfo;
			},
			getList() {
				this.dialogHistory = true;
				this.getCount();
			},
			getCount() {
				let {
					search_zlnlpg_pgrq,
					search_zlnlpg_id,
					search_zlnlpg_syn,
					search_zlnlpg_photo,
					currentPage,
					MyselfPhoto,
					pageSize,
					$moment,
				} = this;
				let where = {};
				if (search_zlnlpg_id) {
					where.serialId = {
						like: search_zlnlpg_id
					};
				}else{
					if(where.serialId){
						delete where.serialId 
					}
				}
				if (search_zlnlpg_pgrq != null && search_zlnlpg_pgrq.length) {
					where.and = [{
							createdAt: {
								gt: $moment(search_zlnlpg_pgrq[0]),
							},
						},
						{
							createdAt: {
								lt: $moment(search_zlnlpg_pgrq[1]),
							},
						},
					];
				} else {
					delete where.and
				}
				getHistoryData({
						filter: {
							where
						}
					})
					.then((res) => {
						this.total_zlnlpg = res.data.length;
						this.get_lnrzlnlList(1);
					})
					.catch((err) => {});
			},
			get_lnrzlnlList(i) {
				this.loadingHistory = true;
				let {
					search_zlnlpg_pgrq,
					search_zlnlpg_id,
					search_zlnlpg_syn,
					search_zlnlpg_photo,
					currentPage,
					MyselfPhoto,
					pageSize,
					$moment,
				} = this;

				let whereObj = {
					order: 'updatedAt',
					orderRule: 'updatedAt',
					skip: pageSize * (currentPage - 1),
					limit: pageSize,
				};
				let where = {};
				if (search_zlnlpg_pgrq != null && search_zlnlpg_pgrq.length) {
					if ($moment(search_zlnlpg_pgrq[0]).format('dd') == $moment(search_zlnlpg_pgrq[1]).format('dd')) {
						this.loadingHistory = false
						this.$message.error('时间错误')
						return
					}

					where.and = [{
							createdAt: {
								gt: $moment(search_zlnlpg_pgrq[0]),
							},
						},
						{
							createdAt: {
								lt: $moment(search_zlnlpg_pgrq[1]),
							},
						},
					];
				} else {
					delete where.and
				}
				if (search_zlnlpg_id) {
					where.serialId = {
						like: search_zlnlpg_id
					};
				}
				if (search_zlnlpg_syn) {
					where.IsSyn = search_zlnlpg_syn;
				}
				whereObj.where = where;
				if (JSON.stringify(whereObj.where) == '{}') {
					delete whereObj.order;
					i = 0;
				} else {
					delete whereObj.orderRule;
				}
				getHistoryData({
						filter: whereObj
					})
					.then((res) => {
						this.loadingHistory = false;

						this.lnrzlnlList = res.data;
					})
					.catch((error) => {
						this.loadingHistory = false;
						this.$notify({
							message: error,
							type: 'error',
						});
					});
			},
			searchReset() {
				this.currentPage = 1;
				this.search_zlnlpg_id = '';
				this.search_zlnlpg_pgrq = [];
				this.search_zlnlpg_syn = '';
				this.search_zlnlpg_photo = false;
			},
			tabChange(e) {
				this.tabHistory = e;
				this.searchReset();
				this.getCount();
			},
			handleSizeChange(val, $event) {
				this.pageSize = val;
				this.loadingHistory = true;
				this.getCount();
			},
			handleCurrentChange(val, event) {
				this.currentPage = val;
				this.loadingHistory = true;
				this.getCount();
			},

			viewRecords(row) {
				this.dialogHistory = false;
				this.serialId = row.serialId;
				this.$emit('inputChange', row.SerialId);
			},

			save() {
				let {
					$store,
					serialId,
					$message
				} = this;
				if (!serialId || serialId.length != 13) {
					$message({
						message: '体检编号有误',
						type: 'error',
					});
					return;
				}

				let zlnl = $store.state.zlnl[0];
				let zybj = $store.state.zybj[0];
				let rzdc = $store.state.rzdc[0];
				let yydc = $store.state.yydc[0];
				let userinfo = $store.state.userinfo;
				let obj = {
					serialId: userinfo.serialId,
					selfCareAbility: zlnl,
					TCMHealthCare: zybj,
					cognitiveInvestigation: rzdc,
					depressionSurvey: yydc,
					...userinfo,
				};

				// zlnl.DisplayName = userinfo.display_name
				// zlnl.Age = userinfo.age
				// zlnl.Gender = userinfo.sex
				// zlnl.SerialId = serialId

				// zybj.DisplayName = userinfo.display_name
				// zybj.Age = userinfo.age
				// zybj.Gender = userinfo.sex
				// zybj.SerialId = serialId

				// rzdc.DisplayName = userinfo.display_name
				// rzdc.Age = userinfo.age
				// rzdc.Gender = userinfo.sex
				// rzdc.SerialId = serialId

				// yydc.DisplayName = userinfo.display_name
				// yydc.Age = userinfo.age
				// yydc.Gender = userinfo.sex
				// yydc.SerialId = serialId

				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				});
				uploadData(obj)
					.then((res) => {
						loading.close();
						$message({
							message: '保存成功 ',
							type: 'success',
						});
						setTimeout(() => {
							this.$router.go(0);
							this.$refs.serialid.focus();
						}, 100);
					})
					.catch((err) => {
						loading.close();
						$message({
							message: err,
							type: 'error',
						});
						console.log(err);
					});

				// inserUpateZlnl(zlnl).then(zlnlRes => {
				// 	inserUpateZybj(zybj).then(zybjRes => {
				// 		inserUpateRzdc(rzdc).then(rzdcRes => {
				// 			inserUpateYydc(yydc).then(yydcRes => {
				// 				loading.close()
				// 				this.$message({
				// 					message: '保存成功 ',
				// 					type: 'success'
				// 				})
				// 				setTimeout(() => {
				// 					this.$router.go(0)
				// 					this.$refs.serialid.focus()
				// 				}, 100)
				// 			}).catch(err => {
				// 				loading.close()
				// 				this.$message({
				// 					message: '抑郁调查保存失败 ',
				// 					type: 'error'
				// 				})
				// 			})
				// 		}).catch(err => {
				// 			loading.close()
				// 			this.$message({
				// 				message: '认知调查保存失败 ',
				// 				type: 'error'
				// 			})
				// 		})
				// 	}).catch(err => {
				// 		loading.close()
				// 		this.$message({
				// 			message: '中医保健保存失败 ',
				// 			type: 'error'
				// 		})
				// 	})
				// }).catch(err => {
				// 	loading.close()
				// 	this.$message({
				// 		message: '自理能力保存失败 ',
				// 		type: 'error'
				// 	})
				// })
			},

			rangeTimeClick() {
				let data = {
					startTime: '',
					endTime: this.$moment(new Date()).format('YYYY-MM-DD'),
				};
				data.startTime = (data.endTime.substr(0, 4) - 1).toString() + data.endTime.substr(4, 10);
				this.updataLoading = true;
				getEveCount(data)
					.then((res) => {
						let firstData = res.data;
						this.updataLoading = false;
						this.$notify({
							message: '获取成功',
							type: 'success',
						});
						this.OldElevateMyself = firstData.OldElevateMyself;
						this.OldMedicalHealthcare = firstData.OldMedicalHealthcare;
						this.OldMedicalCognitivesurvey = firstData.OldMedicalCognitivesurvey;
						this.OldMedicalDepressed = firstData.OldMedicalDepressed;
						this.allUpdata();
					})
					.catch((err) => {
						this.updataLoading = false;
						this.$notify({
							message: err,
							type: 'error',
						});
					});
			},
			allUpdata() {
				let data = {
					startTime: '',
					endTime: this.$moment(new Date()).format('YYYY-MM-DD'),
				};
				data.startTime = (data.endTime.substr(0, 4) - 1).toString() + data.endTime.substr(4, 10);
				this.upDataLoad = true;
				UploadByTime(data)
					.then((res) => {
						this.upDataLoad = false;
						this.upDataMessage = res.data;
						this.$notify({
							message: '上传成功',
							type: 'success',
						});
					})
					.catch((err) => {
						this.upDataLoad = false;
						this.$notify({
							message: err,
							type: 'error',
						});
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.input-list {
		background-color: #e6ebeb;
		border: none;
		padding: 10px 8px;
		border-radius: 5px;
		height: 20px;
	}

	.button-list {
		font-size: 32px !important;
		width: 160px;
		height: 50px;
		color: #ffffff !important;
		padding: 9px 6px !important;
	}

	.shadow-bottom {
		box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 10px;
	}

	td {
		text-align: center;
	}
</style>
