import axios from 'axios'
import {
	MessageBox,
	Message
} from 'element-ui'
import store from '@/store'
import {
	getToken
} from '@/utils/auth'


// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	withCredentials: true, // 跨域请求时发送cookies
	timeout: 10000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
	config => {
		// 在发送请求之前做些什么
		if (store.getters.token) {
			// 让每个请求携带令牌
			// 请根据实际情况修改
			// config.headers['Authorization'] = getToken()
			config.headers['Content-Type'] = "application/json"
		}
		return config
	},
	error => {
		// 处理请求错误
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(
	/**
	 *如果您想获取http信息，如头或状态
	 *请返回response=>response
	 */
	/**
	 *通过自定义代码确定请求状态
	 *这里只是一个例子
	 *您还可以通过HTTP状态代码来判断状态
	 */
	response => {
		const res = response

		return res
	},
	error => {
		let msg = ""
		if (error.response) {
			switch (error.response.status) {
				case 500:
					msg = "请检查是否连接正确的局域网。"
					break;
				case 422:
					msg = "请求格式正确，但是由于含有语义错误，无法响应。"
					break;
				case 404:
					msg = "请求失败，请求所希望得到的资源未被在服务器上发现。"
					break;
				case 403:
					msg = "服务器已经理解请求，但是拒绝执行它。"
					break;
				case 401:
					msg = "当前请求需要用户验证。"
					store.dispatch('admin/resetToken').then(() => {
						location.reload()
					})
					break;
				case 400:
					msg = "数据格式错误，当前请求无法被服务器理解。"
					break;
			}
		} else {
			msg = '请求超时'
		}
		Message({
			message: msg,
			type: 'error'
		})
		return Promise.reject(error)
	}
)

export default service
