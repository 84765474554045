<template>
  <div class="news-box">
    <el-container style="margin-top: 24px">
      <!-- 侧边 -->
      <el-aside width="330px">
        <div style="margin-top: 10px">
          <div style="height: 294px; width: 282px">
            <div style="width: 140px; height: 26px">
              <span style="
                  font-family: Microsoft YaHei;
                  font-weight: 700;
                  color: #000000;
                  font-size: 20px;
                  margin-top: 14px;
                ">关于学会</span>
            </div>
            <div style="display: flex; margin-bottom: 12px">
              <div style="width: 100px; height: 4px; background: #4190c8"></div>
              <div style="
                  width: 182px;
                  margin-top: 4px;
                  height: 1px;
                  background: #4190c8;
                "></div>
            </div>
            <div class="bgHover" style="width: 282px; height: 50px;user-select: none; cursor: pointer"
              v-for="(newarticle, index) in newarticle" :key="index" @click="jump(newarticle.value)">
              <!-- <div> -->
              <div style="display: flex">
                <div class="news-sid" v-if="newarticle.value == '组织机构'">
                  <div style="margin-left: 30px">组织机构</div>
                  <img src="../../assets/party/icon.png" style="position: absolute; left: 0; bottom: 0" />
                </div>

                <div v-else style="
                    width: 204px;
                    height: 21px;
                    margin: 15px 22px 14px 22px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  " @click="jump(newarticle.value)">
                  {{ newarticle.value }}
                </div>
              </div>
              <div style="width: 282px; height: 1px; background: #afafaf"></div>
            </div>
          </div>
        </div>
        <!-- <div style="margin-top: 48px;">
                    <newsTrends :modelName="'aboutSocieties'" :limit="5" :isHidden="true" />
                </div> -->
      </el-aside>

      <!-- 中间 -->
      <el-main class="main">
        <div style="
            width: 100%;
            height: 1px;
            background: #afafaf;
            position: relative;
            top: 35px;
          "></div>
        <div style="display: flex; align-items: center">
          <div style="height: 34px; width: 18px">
            <div style="
                height: 25px;
                width: 18px;
                background-color: rgba(65, 144, 200, 1);
              "></div>
            <div style="
                height: 9px;
                width: 18px;
                background-color: rgba(89, 194, 224, 1);
              "></div>
          </div>
          <span style="margin-left: 10px; font-weight: 550">组织机构</span>
          <span style="
              margin-left: 12px;
              color: #e5e5e5;
              font-size: 12px;
              line-height: 26px;
            ">ORGANIZATION</span>
        </div>

        <div>
          <div style="letter-spacing: 6px">
            <!-- <div v-html="this.newsData.content"></div> -->
            <div style="
                width: 100%;
                color: #4190c8;
                font-size: 16px;
                margin-top: 40px;
              ">
              <div style="display: flex; width: 100%">
                <div style="
                    display: flex;
                    height: 18px;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-right: 2%;
                  ">
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                  </div>
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="width: 90px">理事长:</div>
                  <div style="color: #000000; width: 750px">
                    {{ newsData?.chairman }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="letter-spacing: 6px">
            <!-- <div v-html="this.newsData.content"></div> -->
            <div style="
                width: 100%;
                color: #4190c8;
                font-size: 16px;
                margin-top: 40px;
              ">
              <div style="display: flex; width: 100%">
                <div style="
                    display: flex;
                    height: 18px;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-right: 2%;
                  ">
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                  </div>
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="width: 90px">秘书长:</div>
                  <div style="color: #000000; width: 750px">
                    {{ newsData?.secretary }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="letter-spacing: 6px">
            <!-- <div v-html="this.newsData.content"></div> -->
            <div style="
                width: 100%;
                color: #4190c8;
                font-size: 16px;
                margin-top: 40px;
              ">
              <div style="display: flex; width: 100%">
                <div style="
                    display: flex;
                    height: 18px;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-right: 2%;
                  ">
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                  </div>
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="width: 110px">副理事长:</div>
                  <div style="color: #000000; width: 730px">
                    {{ newsData?.viceChairman }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="letter-spacing: 6px">
            <!-- <div v-html="this.newsData.content"></div> -->
            <div style="
                width: 100%;
                color: #4190c8;
                font-size: 16px;
                margin-top: 40px;
              ">
              <div style="display: flex; width: 100%">
                <div style="
                    display: flex;
                    height: 18px;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-right: 2%;
                  ">
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                  </div>
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="width: 70px">理事:</div>
                  <div style="color: #000000; width: 770px">
                    {{ newsData?.director }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="letter-spacing: 6px">
            <!-- <div v-html="this.newsData.content"></div> -->
            <div style="
                width: 100%;
                color: #4190c8;
                font-size: 16px;
                margin-top: 40px;
              ">
              <div style="display: flex; width: 100%">
                <div style="
                    display: flex;
                    height: 18px;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-right: 2%;
                  ">
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                  </div>
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="width: 70px">顾问:</div>
                  <div style="color: #000000; width: 770px">
                    {{ newsData?.consultant }}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div style="letter-spacing: 6px">
            <!-- <div v-html="this.newsData.content"></div> -->
            <div style="
                width: 100%;
                color: #4190c8;
                font-size: 16px;
                margin-top: 40px;
              ">
              <div style="display: flex; width: 100%">
                <div style="
                    display: flex;
                    height: 18px;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-right: 2%;
                  ">
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                  </div>
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="width: 124px">名誉理事长:</div>
                  <div style="color: #000000; width: 720px">
                    {{ newsData?.honoraryChairman }}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div style="letter-spacing: 6px">
            <!-- <div v-html="this.newsData.content"></div> -->
            <div style="
                width: 100%;
                color: #4190c8;
                font-size: 16px;
                margin-top: 40px;
              ">
              <div style="display: flex; width: 100%">
                <div style="
                    display: flex;
                    height: 18px;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-right: 2%;
                  ">
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                  </div>
                  <div style="
                      display: flex;
                      width: 18px;
                      justify-content: space-between;
                    ">
                    <div style="width: 7px; height: 7px; background: #59c2e0"></div>
                    <div style="width: 7px; height: 7px; background: #4190c8"></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="width: 210px">贵州省数学学会党委:</div>
                  <div style="color: #000000; width: 690px">
                    {{ newsData?.partyOrg }}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getOrganizations, getOrganizationsCount } from '@/api/aboutSociety'
import newsTrends from '@/view/components/news'
export default {
  name: 'Institutearticles',
  components: {
    newsTrends
  },
  data() {
    return {
      newarticle: [
        {
          value: '学会简介',
          label: '学会简介'
        },
        {
          value: '学会章程',
          label: '学会章程'
        },
        {
          value: '组织机构',
          label: '组织机构'
        }

      ],
      newsData: []
    }
  },
  methods: {
    getData() {
      getOrganizations({
        order: 'createdAt DESC'
      })
        .then((res) => {
          this.newsData = res.data[0]
        })
        .catch((err) => { })
    },
    jump(data) {
      if (data == '学会章程') {
        this.jumpTearticles()
      } else if (data == '学会简介') {
        this.jumpIntroduction()
      } else if (data == '联系我们') {
        this.jumpConnection()
      }
    },
    jumpIntroduction() {
      this.$router.push('/Instituteintroduction')
    },
    jumpOrgan() {
      this.$router.push('/Instituteorganizational')
    },
    jumpTearticles() {
      this.$router.push('/Institutearticles')
    },
    jumpConnection() {
      this.$router.push('/Instituteconnection')
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.news-box {
  height: auto;

  .topTitle {
    position: relative;
    width: 280px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .news-sid {
    margin-top: 1px;
    position: relative;
    width: 281px;
    height: 50px;
    line-height: 50px;
    background-color: #ebf4fb;
  }

  .main {
    height: auto;
    // height: 100%;
  }

  .bgHover:hover {
    background: #e6e6e6;
    color: rgb(86, 193, 255) !important;
  }
}
</style>
