import { render, staticRenderFns } from "./campus.vue?vue&type=template&id=7046cb9e&scoped=true&"
import script from "./campus.vue?vue&type=script&lang=js&"
export * from "./campus.vue?vue&type=script&lang=js&"
import style0 from "./campus.vue?vue&type=style&index=0&id=7046cb9e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7046cb9e",
  null
  
)

export default component.exports