<template>
<div>
    <el-container>
        <el-aside style="width: 282px;margin-top: 24px;">
            <div style="width: 140px;height: 26px;">
                <span style="font-family:Microsoft YaHei;font-weight:700;color:#000000;font-size:20px;margin-top: 14px;">高中数学联赛</span>
            </div>
            <div style="display: flex;">
                <div style="width: 100px;height: 4px;background:#4190c8 ;"></div>
                <div style="width: 182px;height: 1px;background:#4190c8 ;"></div>
            </div>
            <div style="width: 282px;height: 50px;background-color:  rgba(65, 144, 200,0.1);display: flex;margin-top: 10px;">
                <div style="height: 50px;width: 10px;">
                    <div style="height: 41px;width: 10px;background-color: rgba(65, 144, 200, 1);">
                    </div>
                    <div style="height: 9px;width: 10px;background-color: rgba(89, 194, 224, 1)">
                    </div>
                </div>
                <div style="font-family:Microsoft YaHei;color:#000000;font-size:16px;margin-top: 15px;margin-bottom: 14px;margin-right: 148px;margin-left: 12px;width: 112px;height: 21px;">
                    高中数学联赛
                </div>
            </div>
            <!-- <div style="height: 294px;width: 282px;margin-top: 48px;">
                <div style="width: 140px;height: 26px;">
                    <span style="font-family:Microsoft YaHei;font-weight:700;color:#000000;font-size:20px;margin-top: 14px;">最新动态</span>
                </div>
                <div style="display: flex;">
                    <div style="width: 100px;height: 4px;background:#4190c8 ;"></div>
                    <div style="width: 182px;height: 1px;background:#4190c8 ;"></div>
                </div>
                <div style="margin-top: 10px;">
                    <div style="width: 282px;height: 50px;" v-for="(newarticle,index) in articleListAside" :key="index">

                        <div style="display: flex;">
                            <div style="width:204px;height:21px;margin: 15px 22px 14px 22px;">
                                <router-link :to="'/newarticle/'+newarticle.id">
                                    <span style="font-family:Microsoft YaHei;color:#000000;font-size:16px;white-space:nowrap;width: 204px !important;float: left !important;overflow: hidden !important;text-overflow: ellipsis !important;">
                                        {{newarticle.title}}
                                    </span>
                                </router-link>
                            </div>
                            <div style="padding-top: 19px;">
                                <span class="listTime1">{{ newarticle.publishTime |dateformat('MM-DD') }}</span>
                            </div>
                        </div>
                        <div style="width: 282px;height: 1px;background:#afafaf;"></div>

                    </div>
                </div>
            </div> -->
            <div style="margin-top: 48px;">
                <newsTrends :modelName="'Highschoolmaths'" :limit="5" :isHidden="true"/>
            </div>

        </el-aside>
        <el-main class="emain">
            <div v-for="(article,index) in articleList" :key="index" style="margin-left: 56px;margin-top: 24px;display: flex;">

                <div style="display: flex;">
                    <div style="margin-top: 16px;margin-bottom: 16px;margin-right: 11px;">
                        <div style="width: 44px;height: 47px;margin-right: 14px;margin-left: 8px;"><span style="font-family:Microsoft YaHei;font-weight:700;color:#4190c8;font-size:36px;">{{ article.publishTime |dateformat('DD') }}</span></div>
                        <div style="width: 67px;height: 21px;margin-top: 20px;"><span style="font-family:Microsoft YaHei;font-weight:700;color:#4190c8;font-size:16px;">{{ article.publishTime |dateformat('YYYY-MM') }}</span></div>
                    </div>
                    <div style="width:1px;height:120px;background:#afafaf;"></div>

                </div>
                <div style="margin-left: 13px;">
                    <div style="height: 21px;width: 656px;margin-bottom: 24px;">
                        <router-link :to="`/contentDetail?model=${'Highschoolmaths'}&id=${article.id}`" target="_blank">
                            <span style="font-family: Microsoft YaHei; color: #4190c8; font-size: 16px">
                                {{article.title.length > 38? article.title.slice(0,38)+'......': article.title}}
                            </span>
                        </router-link>

                        <!-- <router-link :to="`/contentDetail?model=${modelName}&id=${newarticle.id}`" target="_blank">
                            {{ newarticle.title }}
                        </router-link> -->

                    </div>
                    <div style="height: 75px;width: 768px;">
                        <div style="width: 768px;height: 54px;">
                            <!-- <div v-html="article.content"></div> -->
                            <span style="font-size: 14px;font-family: Microsoft YaHei;">
                                {{article.abstract.length > 155? article.abstract.slice(0,155)+'......': article.abstract}}
                            </span>
                        </div>
                        <div style="width: 64px;height: 21px;margin-left: 704px;">
                            <!-- <router-link :to="'/contentDetail?model=Highschoolmaths&id=' + article.id">
                                <span style="font-family: Microsoft YaHei; color: #4190c8; font-size: 16px">
                                    立即查看
                                </span>
                            </router-link> -->

                            <router-link :to="`/contentDetail?model=${'Highschoolmaths'}&id=${article.id}`" target="_blank">
                                <span style="font-family: Microsoft YaHei; color: #4190c8; font-size: 16px">
                                    立即查看
                                </span>
                            </router-link>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div style="margin-left: 590px;margin-top: 50px;">
                <el-pagination :pager-count="5" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :total="total" background layout="prev, pager, next" style="text-align: center;">
                </el-pagination>
            </div> -->
            <!-- <div class="box" style="margin-top: 62px;margin-bottom: 100px;">
                <ul>
                    <li v-for="(article,index) in articleList" :key="'article'+index">
                        <router-link :to="'/article/'+article.id">
                            <span>{{article.title}} </span>
                            <span>{{article.abstract}} </span>
                            <span class="listTime">{{ article.publishTime |dateformat('YYYY-MM-DD HH:mm:ss') }}</span>
                        </router-link>
                    </li>
                </ul>
                <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :total="total" background layout="prev, pager, next" style="text-align: center;">
                </el-pagination>
            </div> -->
        </el-main>
    </el-container>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :total="total"
      background
      layout="prev, pager, next"
      style="text-align: center; margin-left: 840px"
    >
    </el-pagination>
</div>
</template>

<script>
import
newsTrends
from '@/view/components/news'
export default {
    components: {
        newsTrends
    },
    data() {
        return {
            // columnId: "",
            // thisColumn: {},
            articleList: [],
            articleListAside: [],
            total: 0,
            pageSize: 3,
            pageCount: 0,
            currentPage: 1,
        }
    },
    created() {
        this.refresh();
    },
    watch: {
        $route: 'refresh'
    },
    methods: {
        refresh() {
            // this.columnId = this.$route.params.columnId;
            this.getListCount();
            // this.getColumn();
        },
        getListCount() {
            this.requestMy({
                    url: '/Highschoolmaths/count',
                    method: 'get',
                    params: {
                        where: {
                            state: 0,
                            // class: '高中数学联赛',
                            hidden: 1
                            // columnId: this.columnId
                        }
                    }
                })
                .then(response => {
                    this.total = response.count;
                    this.pageCount = Math.ceil(this.total / this.pageSize);
                    this.getArticleList(this.currentPage, this.pageSize)
                    this.getArticleListAside(this.currentPage, 5)

                })
                .catch(error => {
                    console.log(error);
                });

        },
        getArticleList(currentPage, pageSize) {

            this.requestMy({
                url: '/Highschoolmaths',
                method: 'get',
                params: {
                    filter: {
                        where: {
                            state: 0,
                            // class: '高中数学联赛',
                            hidden: 1
                        },
                        fields: {
                            // content: false,
                            id: true,
                            title: true,
                            publishTime: true,
                            class: true,
                            abstract: true,
                            content: true
                            // imgURL: true,
                            // abstract: true
                        },
                        order: 'publishTime DESC', //升序
                        limit: pageSize,
                        skip: (currentPage - 1) * pageSize,
                    }
                }
            }).then(response => {
                this.articleList = response
            }).catch(error => {
                console.log(error)
            })
        },
        getArticleListAside(currentPage, pageSize1) {
            this.requestMy({
                url: '/Highschoolmaths',
                method: 'get',
                params: {
                    filter: {
                        where: {
                            state: 0,
                            // class: '高中数学联赛',
                            hidden: 1
                        },
                        fields: {
                            // content: false,
                            id: true,
                            title: true,
                            publishTime: true,
                            class: true,
                            abstract: true,
                            content: true
                            // imgURL: true,
                            // abstract: true
                        },
                        order: 'publishTime DESC', //升序
                        limit: pageSize1,
                        // skip: (currentPage - 1) * pageSize,
                    }
                }
            }).then(response => {
                this.articleListAside = response
            }).catch(error => {
                console.log(error)
            })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getArticleList(this.currentPage, this.pageSize)
        }
    }
};
</script>

<style>
.emain {
    padding: 0 !important;
    margin-left: 0px;
    overflow: hidden !important;
}

.listTime1 {
    width: 26px;
    height: 16px;
    font-family: Microsoft YaHei;
    color: #afafaf;
    font-size: 12px;
    text-align: right;
    /* margin-left: 30px; */
    /* margin-top: 19px !important;*/
    /* padding-top: 19px;  */
    margin-bottom: 15px;
}

/* .listTitle {
    font-size: 20px;
    color: #3c3e42;
}

.listP {
    font-size: 16px;
    color: #7b7b7b;
} */

/* .listTime {
    float: right;
    font-size: 10px;
    color: #a7a7a7;
}

.fmIMG { */
/* width: 195px;

height: 122px;

box-shadow: 0px 0px 10px 0px #d1d1d1;

border-radius: 10px;

background-repeat: no-repeat;

background-position: center;

background-size: 160px 100px;

float: left;

margin-right: 25px; */

/* float: left;
    width: 195px;
    height: 122px;
    box-shadow: 0 0 10px 0 #d1d1d1;
    border-radius: 10px;
    /* background-image: url('../assets/icon71.png'); */
/* background-repeat: no-repeat;
    background-position: center;
    background-size: 180px 100px;
    margin-right: 25px;

    padding-top: 22px;
    position: relative;
} */

/* .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #606265
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    background-color: white;
}

.columnT-enter-active,
.columnT-leave-active {
    transition: all 1s;
} */
</style>
