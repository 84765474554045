<template>
  <div class="partyNotification">
    <div class="headPhoto">
      <img src="../../assets/party/head.png" />
    </div>
    <div class="party_container">
      <div class="sidebar">
        <div class="topTitle">
          <span>学会动态</span>
          <img
            src="../../assets/homePage/shortTitleLine.png"
            style="width: 281px; position: absolute; bottom: 0; left: 0"
          />
        </div>
        <div>
          <div
            style="
              margin-top: 10px;
              position: relative;
              width: 281px;
              height: 50px;
              line-height: 50px;
              background-color: #ebf4fb;
              cursor: pointer;
              border-bottom: 1px solid #b9bbbe;
            "
          >
            <div style="margin-left: 30px">通知公告</div>
            <img
              src="../../assets/party/icon.png"
              style="position: absolute; left: 0; bottom: 0"
            />
          </div>
          <!-- <div
            style="
              width: 281px;
              height: 50px;
              line-height: 50px;
              cursor: pointer;
              border-bottom: 1px solid #b9bbbe;
            "
          >
            <div style="margin-left: 30px" @click="jumpMember()">组织成员</div>
          </div> -->
          <div
            style="
              width: 281px;
              height: 50px;
              line-height: 50px;
              cursor: pointer;
              border-bottom: 1px solid #b9bbbe;
            "
          >
            <div style="margin-left: 30px" @click="jumpState()">党建动态</div>
          </div>
        </div>
        <div class="topTitle" style="margin-top: 30px">
          <span>最新动态</span>
          <img
            src="../../assets/homePage/shortTitleLine.png"
            style="width: 281px; position: absolute; bottom: 0; left: 0"
          />
        </div>
        <div>
          <div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                width: 281px;
                height: 50px;
                line-height: 50px;
                cursor: pointer;
                border-bottom: 1px solid #b9bbbe;
              "
              v-for="(item, index) in newStateData"
              :key="index"
              @click="jumpContentDetail('notices', item.id)"
              v-show="index < 5"
            >
              <div
                style="
                  width: 200px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  margin-left: 25px;
                "
              >
                {{ item.title }}
              </div>
              <div style="color: #b9bbbe; font-size: 12px; margin-right: 2px">
                {{ $moment(item.createdAt).format('MM-DD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content" style="width: 880px; height: 600px; margin-top: 4px">
        <div
          class="content_title"
          style="
            width: 880px;
            height: 36px;
            position: relative;
            border-bottom: 1px solid #b9bbbe;
            line-height: 36px;
          "
        >
          <span style="margin-left: 30px; font-weight: 550">通知公告</span>
          <span
            style="
              margin-left: 10px;
              color: #e5e5e5;
              font-size: 12px;
              line-height: 26px;
            "
            >NOTICE ANNOUNCEMENT</span
          >
          <img
            src="../../assets/party/icon1.png"
            style="position: absolute; left: 0; bottom: -2px"
          />
        </div>
        <div
          style="
            width: 880px;
            height: 120px;
            background-color: #fff;
            margin-top: 10px;
            margin-bottom: 50px;
            position: relative;
          "
          v-for="item in noticeData"
          :key="item"
        >
          <div style="display: flex">
            <div
              style="
                width: 70px;
                height: 120px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                border-right: 1px solid #b9bbbe;
              "
            >
              <div
                style="
                  font-size: 30px;
                  color: #4190c8;
                  font-weight: 700;
                  margin-right: 5px;
                "
              >
                {{ $moment(item.createdAt).format('DD') }}
              </div>
              <div
                style="
                  font-size: 14px;
                  color: #4190c8;
                  font-weight: 700;
                  margin-bottom: 10px;
                "
              >
                {{ $moment(item.createdAt).format('YYYY-MM') }}
              </div>
            </div>
            <div
              style="
                width: 435px;
                height: 120px;
                background-color: #fff;
                margin-left: 20px;
                position: relative;
              "
            >
              <div
                style="
                  width: 320px;
                  font-size: 14px;
                  color: #4190c8;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  cursor: pointer;
                "
                @click="jumpContentDetail('notices', item.id)"
              >
                {{ item.title }}
              </div>
              <div
                style="
                  width: 350px;
                  font-size: 14px;
                  display: -webkit-box;
                  -webkit-line-clamp: 4;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  margin-top: 20px;
                "
              >
                {{ item.abstract }}
              </div>
              <div
                style="
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  width: 50px;
                  height: 20px;
                  font-size: 12px;
                  color: #4190c8;
                  cursor: pointer;
                "
                @click="jumpContentDetail('notices', item.id)"
              >
                立即查看
              </div>
            </div>
          </div>
          <img
            v-if="item.imgUrl"
            :src="baseUrl + item.imgUrl"
            style="
              width: 283px;
              height: 120px;
              position: absolute;
              right: 0;
              bottom: 0;
              display: block;
            "
          />
          <img
            v-else
            src="../../assets/party/content.png"
            style="
              width: 283;
              height: 120px;
              position: absolute;
              right: 0;
              bottom: 0;
            "
          />
        </div>
      </div>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :total="total"
      background
      layout="prev, pager, next"
      style="text-align: center; margin-left: 840px"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getNotice } from '../../api/notice'
export default {
  name: 'partyNotification',
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API + 'containers/file/download/',
      newStateData: [],
      noticeData: [{}, {}, {}],
      total: 0,
      pageSize: 3,
      pageCount: 0,
      currentPage: 1
    }
  },
  created() {
    this.getCount()
    this.getStateData()
    this.getNoticeData()
  },
  methods: {
    getCount() {
      let filter = {
        where: {
          hidden: 1
        }
      }
      getNotice(filter)
        .then((res) => {
          this.total = res.data.length
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getNoticeData() {
      let filter = {
        where: {
          hidden: 1
        },
        limit: this.pageSize,
        order: 'createdAt DESC', //升序
        skip: (this.currentPage - 1) * this.pageSize
      }
      getNotice(filter)
        .then((res) => {
          this.noticeData = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getNoticeData()
    },
    getStateData() {
      let filter = {
        where: {
          hidden: 1
        },
        limit: 5,
        order: 'createdAt DESC' //升序
      }
      getNotice(filter)
        .then((res) => {
          this.newStateData = res.data
          // this.noticeData = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    jumpMember() {
      this.$router.push('/partymember')
    },
    jumpState() {
      this.$router.push('/partystate')
    },
    jumpContentDetail(modelName, id) {
      let routeData = this.$router.resolve({
        path: '/contentDetail',
        query: { model: modelName, id: id }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push(`/contentDetail?model=${modelName}&id=${id}`);
    }
  }
}
</script>

<style lang="scss" scoped>
.partyNotification {
  .party_container {
    width: 1200px;
    height: 550px;
    display: flex;
    justify-content: space-between;

    // background-color: hotpink;
    .sidebar {
      width: 281px;

      //   background-color: skyblue;
      .topTitle {
        position: relative;
        width: 280px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
