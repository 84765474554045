<template>
    <div class="news-box">


        <el-container style="margin-top: 24px;">
            <!-- 侧边 -->
            <el-aside width="330px">

                <div style="margin-top: 10px">
                    <div style="height: 294px; width: 282px">
                        <div style="width: 140px; height: 26px">
                            <span
                                style="font-family: Microsoft YaHei;font-weight: 700; color: #000000;font-size: 20px;margin-top: 14px;">关于学会</span>
                        </div>
                        <div style="display: flex;margin-bottom: 12px;">
                            <div style="width: 100px; height: 4px; background: #4190c8"></div>
                            <div style="width: 182px;margin-top: 4px; height: 1px; background: #4190c8"></div>
                        </div>
                        <div class="bgHover" style="width: 282px;height: 50px ;cursor: pointer;user-select: none;"
                            v-for="(newarticle, index) in newarticle" :key="index" @click="jump(newarticle.value)">
                            <!-- <div> -->
                            <div style="display: flex">
                                <div class="news-sid" v-if="newarticle.value == '学会简介'">
                                    <div style="margin-left: 30px">学会简介</div>
                                    <img src="../../assets/party/icon.png"
                                        style="position: absolute; left: 0; bottom: 0;" />
                                </div>

                                <div v-else
                                    style=" width: 204px;height: 21px;margin: 15px 22px 14px 22px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"
                                    @click="jump(newarticle.value)">
                                    {{ newarticle.value }}
                                </div>
                            </div>
                            <div style="width: 282px; height: 1px; background: #afafaf"></div>
                        </div>
                    </div>
                </div>
                <!-- <div style="margin-top: 48px;">
                    <newsTrends :modelName="'aboutSocieties'" :limit="5" :isHidden="true" />
                </div> -->
            </el-aside>




            <!-- 中间 -->
            <el-main class="main">
                <div style="width:100%;height:1px;background:#afafaf;position: relative;top: 35px;"></div>
                <div style="display: flex;align-items: center;">
                    <div style="height:34px; width: 18px">
                        <div style="height:25px; width: 18px; background-color: rgba(65, 144, 200, 1)"></div>
                        <div style="height: 9px; width: 18px; background-color: rgba(89, 194, 224, 1)"></div>
                    </div>
                    <span style="margin-left: 10px; font-weight: 550">学会简介</span>
                    <span style=" margin-left: 12px;color: #e5e5e5;font-size: 12px;line-height: 26px;">BRIEF
                        INTRODUCTION</span>
                </div>

                <div>
                    <p class="ql-editor" v-html="newsData.content"></p>
                </div>

            </el-main>
        </el-container>
    </div>
</template>
  
<script>
import {
    getSocietiy,
    getSocietiyCount,
} from '@/api/aboutSociety'
import
newsTrends
    from '@/view/components/news'
export default {
    name: 'Institutearticles',
    components: {
        newsTrends
    },
    data() {
        return {
            newarticle: [
                {
                    value: '学会简介',
                    label: '学会简介'
                }, {
                    value: '学会章程',
                    label: '学会章程'
                }, {
                    value: '组织机构',
                    label: '组织机构'
                }
            ],
            newsData: []
        }
    },
    methods: {
        getData() {
            getSocietiy({
                where: {
                    type: '学会简介'
                },
                order: 'publishTime DESC',
            }).then(res => {
                this.newsData = res.data[0]
            }).catch(err => {

            })
        },
        jump(data) {
            if (data == '学会章程') {
                this.jumpTearticles()
            } else if (data == '组织机构') {
                this.jumpOrgan()
            } else if (data == '联系我们') {
                this.jumpConnection()
            }
        },
        jumpIntroduction() {
            this.$router.push('/Instituteintroduction')
        },
        jumpOrgan() {
            this.$router.push('/Instituteorganizational')
        },
        jumpTearticles() {
            this.$router.push('/Institutearticles')
        },
        jumpConnection() {
            this.$router.push('/Instituteconnection')
        },
    },
    created() {
        this.getData();
    },
}
</script>
  
<style lang="scss" scoped>
.news-box {
    height: auto;

    .topTitle {
        position: relative;
        width: 280px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .news-sid {
        margin-top: 1px;
        position: relative;
        width: 281px;
        height: 50px;
        line-height: 50px;
        background-color: #ebf4fb;
    }

    .main {
        height: auto;
        // height: 100%;
    }

    .bgHover:hover {
        background: #e6e6e6;
        color: rgb(86, 193, 255) !important;
    }

    .ql-indent-1 {
        padding-left: 0px;
        text-indent: 1em;
    }

    .ql-indent-2 {
        padding-left: 0px !important;
        text-indent: 2em !important;
    }

    .ql-indent-3 {
        padding-left: 0px !important;
        text-indent: 3em !important;
    }

    .ql-indent-4 {
        padding-left: 0px !important;
        text-indent: 4em !important;
    }

    .ql-indent-5 {
        padding-left: 0px !important;
        text-indent: 5em !important;
    }

    .ql-indent-6 {
        padding-left: 0px !important;
        text-indent: 6em !important;
    }

    .ql-indent-7 {
        padding-left: 0px !important;
        text-indent: 7em !important;
    }

    .ql-indent-8 {
        padding-left: 0px !important;
        text-indent: 8em !important;
    }

    .ql-editor {
        overflow-y: hidden !important;
    }

    .ql-editor {
        box-sizing: border-box;
        line-height: 1.42;
        height: 99%;
        outline: none;
        overflow-y: auto;
        padding: 12px 15px;
        -o-tab-size: 4;
        tab-size: 4;
        -moz-tab-size: 4;
        text-align: left;
        white-space: pre-wrap;
        margin: 0;
        word-wrap: break-word;
    }

}
</style>
  