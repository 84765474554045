<template>
  <div class="partyMember">
    <div class="headPhoto">
      <img src="../../assets/party/head.png" />
    </div>
    <div class="party_container">
      <div class="sidebar">
        <div class="topTitle">
          <span>学会动态</span>
          <img
            src="../../assets/homePage/shortTitleLine.png"
            style="width: 281px; position: absolute; bottom: 0; left: 0"
          />
        </div>
        <div>
          <div
            style="
              margin-top: 10px;
              width: 281px;
              height: 50px;
              line-height: 50px;
              cursor: pointer;
              border-bottom: 1px solid #b9bbbe;
            "
          >
            <div style="margin-left: 30px" @click="jumpNotice()">通知公告</div>
          </div>
          <div
            style="
              position: relative;
              width: 281px;
              height: 50px;
              line-height: 50px;
              background-color: #ebf4fb;
              cursor: pointer;
              border-bottom: 1px solid #b9bbbe;
            "
          >
            <div style="margin-left: 30px">组织成员</div>
            <img
              src="../../assets/party/icon.png"
              style="position: absolute; left: 0; bottom: 0"
            />
          </div>
          <div
            style="
              width: 281px;
              height: 50px;
              line-height: 50px;
              cursor: pointer;
              border-bottom: 1px solid #b9bbbe;
            "
          >
            <div style="margin-left: 30px" @click="jumpState()">党建动态</div>
          </div>
        </div>
      </div>
      <div class="content" style="width: 880px; height: 600px; margin-top: 4px">
        <div
          class="content_title"
          style="
            width: 880px;
            height: 36px;
            position: relative;
            border-bottom: 1px solid #b9bbbe;
            line-height: 36px;
          "
        >
          <span style="margin-left: 30px; font-weight: 550">组织成员</span>
          <span
            style="
              margin-left: 10px;
              color: #e5e5e5;
              font-size: 12px;
              line-height: 26px;
            "
            >ORGANIZATION MEMBERS</span
          >
          <img
            src="../../assets/party/icon1.png"
            style="position: absolute; left: 0; bottom: -2px"
          />
        </div>

        <div style="letter-spacing: 6px">
          <!-- <div v-html="this.newsData.content"></div> -->
          <div
            style="
              display: flex;
              width: 100%;
              color: #4190c8;
              font-size: 16px;
              justify-content: space-between;
              margin-top: 40px;
            "
          >
            <div style="display: flex; width: 95%">
              <div
                style="
                  display: flex;
                  height: 18px;
                  flex-direction: column;
                  justify-content: space-between;
                  padding-right: 2%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                </div>
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                </div>
              </div>
              <div style="display: flex">
                <div style="width: 90px">理事长:</div>
                <div style="color: #000000; width: 750px">
                  {{ memberData[0]?.chairman }}
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              width: 100%;
              color: #4190c8;
              font-size: 16px;
              justify-content: space-between;
              margin-top: 40px;
            "
          >
            <div style="display: flex; width: 95%">
              <div
                style="
                  display: flex;
                  height: 18px;
                  flex-direction: column;
                  justify-content: space-between;
                  padding-right: 2%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                </div>
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                </div>
              </div>
              <div style="display: flex">
                <div style="width: 90px">秘书长:</div>
                <div style="color: #000000; width: 750px">
                  {{ memberData[0]?.secretary }}
                </div>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              width: 100%;
              color: #4190c8;
              font-size: 16px;
              justify-content: space-between;
              margin-top: 40px;
            "
          >
            <div style="display: flex; width: 95%">
              <div
                style="
                  display: flex;
                  height: 18px;
                  flex-direction: column;
                  justify-content: space-between;
                  padding-right: 2%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                </div>
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                </div>
              </div>
              <div style="display: flex">
                <div style="width: 110px">副理事长:</div>
                <div style="color: #000000; width: 750px">
                  {{ memberData[0]?.viceChairman }}
                </div>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              width: 100%;
              color: #4190c8;
              font-size: 16px;
              justify-content: space-between;
              margin-top: 40px;
            "
          >
            <div style="display: flex; width: 95%">
              <div
                style="
                  display: flex;
                  height: 18px;
                  flex-direction: column;
                  justify-content: space-between;
                  padding-right: 2%;
                "
              >
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                </div>
                <div
                  style="
                    display: flex;
                    width: 18px;
                    justify-content: space-between;
                  "
                >
                  <div
                    style="width: 7px; height: 7px; background: #59c2e0"
                  ></div>
                  <div
                    style="width: 7px; height: 7px; background: #4190c8"
                  ></div>
                </div>
              </div>
              <div style="display: flex">
                <div style="width: 70px">理事:</div>
                <div style="color: #000000; width: 750px">
                  {{ memberData[0]?.director }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getpartyAct } from '../../api/partyAct'

export default {
  name: 'partyMember',
  data() {
    return {
      newStateData: [],
      memberData: [{}],
      total: 10,
      pageSize: 3,
      pageCount: 0,
      currentPage: 1
    }
  },
  created() {
    this.getData()
    this.getMemberData()
    // this.getCount();
  },
  methods: {
    getMemberData() {
      let filter = {
        where: {
          type: '组织成员',
          hidden: 1
        },
        limit: 1,
        order: 'createdAt DESC' //升序
      }
      getpartyAct(filter).then((res) => {
        this.memberData = res.data
      })
    },
    getData() {
      let filter = {
        where: {
          type: '组织成员',
          state: 0
        }
      }
      getpartyAct(filter).then((res) => {
        this.newStateData = res.data
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getMemberData()
    },
    jumpNotice() {
      this.$router.push('/partynotification')
    },
    jumpState() {
      this.$router.push('/partystate')
    },
    jumpNewState(item) {},
    jumpContentDetail(modelName, id) {
      this.$router.push(`/contentDetail?model=${modelName}&id=${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.partyMember {
  .party_container {
    width: 1200px;
    height: 550px;
    display: flex;
    justify-content: space-between;
    .sidebar {
      width: 281px;
      .topTitle {
        position: relative;
        width: 280px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
