<template>
    <div class="news-box">


        <el-container style="margin-top: 24px;">
            <!-- 侧边 -->
            <el-aside width="330px">

                <div style="margin-top: 10px">
                    <div style="height: 294px; width: 282px">
                        <div style="width: 140px; height: 26px">
                            <span
                                style="font-family: Microsoft YaHei;font-weight: 700; color: #000000;font-size: 20px;margin-top: 14px;">关于学会</span>
                        </div>
                        <div style="display: flex;margin-bottom: 12px;">
                            <div style="width: 100px; height: 4px; background: #4190c8"></div>
                            <div style="width: 182px;margin-top: 4px; height: 1px; background: #4190c8"></div>
                        </div>
                        <div class="bgHover" style="width: 282px;height: 50px ;cursor: pointer;user-select: none;" @click="jump(newarticle.value)" 
                            v-for="(newarticle, index) in newarticle" :key="index">
                            <!-- <div> -->
                            <div style="display: flex">
                                <div class="news-sid" v-if="newarticle.value == '学会章程'">
                                    <div style="margin-left: 30px">学会章程</div>
                                    <img src="../../assets/party/icon.png"
                                        style="position: absolute; left: 0; bottom: 0;" />
                                </div>

                                <div v-else
                                    style=" width: 204px;height: 21px;margin: 15px 22px 14px 22px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"
                                    @click="jump(newarticle.value)">
                                    {{ newarticle.value }}
                                </div>
                            </div>
                            <div style="width: 282px; height: 1px; background: #afafaf"></div>
                        </div>
                    </div>
                </div>
                <newsTrends :modelName="'learnRules'" :limit="5" :isHidden="true" :type="'学会章程'" />
            </el-aside>




            <!-- 中间 -->
            <el-main class="main">
                <div style="width:100%;height:1px;background:#afafaf;position: relative;top: 35px;"></div>
                <div style="display: flex;align-items: center;">
                    <div style="height:34px; width: 18px">
                        <div style="height:25px; width: 18px; background-color: rgba(65, 144, 200, 1)"></div>
                        <div style="height: 9px; width: 18px; background-color: rgba(89, 194, 224, 1)"></div>
                    </div>
                    <span style="margin-left: 10px; font-weight: 550">学会章程</span>
                    <span style=" margin-left: 12px;color: #e5e5e5;font-size: 12px;line-height: 26px;">RULES</span>
                </div>
                <div v-for="(item, index) in newsData" :key="index" style="margin-top: 24px; display: flex; width: 99%">
                    <div style="display: flex">
                        <div style="margin-top: 16px; margin-bottom: 16px;margin-right: 11px;width: 90%; ">
                            <div style="width: 10%;height: 47px;margin-right: 14px;margin-left: 8px;">
                                <span style="font-weight: 700; color: #4190c8; font-size: 36px">{{ item.publishTime |
                                    dateformat("DD")
                                }}</span>
                            </div>
                            <div style="width: 100%; height: 21px; margin-top: 20px">
                                <span style="font-weight: 700; color: #4190c8; font-size: 16px">{{ item.publishTime |
                                    dateformat("YYYY-MM") }}</span>
                            </div>
                        </div>
                        <div style="width: 1px; height: 120px; background: #afafaf"></div>
                    </div>

                    <div style="margin-left: 24px; width: 50%; font-size: 16px">
                        <div style="
                  height: 21px;
                  width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  -o-text-overflow: ellipsis;
                ">
                            <router-link :to="`/contentDetail?model=${'learnRules'}&id=${item.id}`" target="_blank">
                                <span style="
                      font-family: Microsoft YaHei;
                      color: #4190c8;
                      font-size: 16px;
                      width: 100%;
                    ">
                                    {{ item.title }}
                                </span>
                            </router-link>
                        </div>
                        <div style="
                  width: 100%;
                  height: 62px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                ">
                           <span>
                    {{ item.abstract }}
                  </span>
                        </div>
                        <div style="float: right; margin-top: 2px">
                            <router-link :to="`/contentDetail?model=${'learnRules'}&id=${item.id}`" target="_blank">
                                <span style="
                      font-family: Microsoft YaHei;
                      color: #4190c8;
                      font-size: 16px;
                    ">
                                    立即查看
                                </span>
                            </router-link>
                        </div>
                    </div>
                    <div style="margin-left: 47px; width: 33%; height: 120px">
                        <img v-if="item.imgUrl" :src="baseUrl + item.imgUrl" style="width: 282px; height: 120px" />
                        <div v-else class="photo">
                            <img src="@/assets/news.png" style="width: 282px; height: 120px" />
                        </div>
                    </div>
                </div>
                <div style="margin-top: 18.2%;margin-left:70%;">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
                        :page-size="pageSize" :total="total" background layout="prev, pager, next"
                        style="text-align: center">
                    </el-pagination>
                </div>
            </el-main>
        </el-container>
    </div>
</template>
  
<script>
import {
    getLearnRules,
    getLearnRulesCount
} from '@/api/aboutSociety'
import
newsTrends
    from '@/view/components/news'
export default {
    name: 'Institutearticles',
    components: {
        newsTrends
    },
    data() {
        return {
            newarticle: [
                {
                    value: '学会简介',
                    label: '学会简介'
                }, {
                    value: '学会章程',
                    label: '学会章程'
                }, {
                    value: '组织机构',
                    label: '组织机构'
                }],
            newsData: [],
            modelName: 'learnRules',
            baseUrl:process.env.VUE_APP_BASE_API + 'containers/file/download/',
            pageSize: 3,
            pageCount: 0,
            currentPage: 1,
        }
    },
    methods: {
        getData(currentPage, pageSize) {
            let params = {
                where: {
                    hidden: 1,
                    type: '学会章程'
                },
                order: "publishTime DESC", //升序
                limit: pageSize,
                skip: (currentPage - 1) * pageSize,
            };
            getLearnRules(
                params
            ).then(res => {
                this.newsData = res.data
                // this.getParam(this.newsData);
            }).catch(err => {

            })
        },
        getListCount() {
            let where = {
                hidden: 1,
                type: '学会章程'
            };
            getLearnRulesCount({ where }).then((response) => {

                this.total = response.data.count;
                this.getData(this.currentPage, this.pageSize);
            }).catch((err) => { });
        },
        // getParam(list) {
        //     for (let i = 0; i < list.length; i++) {
        //         // list[i].content.replace(
        //         //   /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
        //         //   (match, capture) => {
        //         //     this.newsData[i] = { ...this.newsData[i], ...{ img: capture } };
        //         //   }
        //         // );
        //         let tpm = list[i].content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g);
        //         let text = tpm.replace("undefined", "");
        //         this.newsData[i] = { ...this.newsData[i], ...{ text: text } };
        //     }
        // },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData(this.currentPage, this.pageSize);
        },
        jump(data) {
            if (data == '学会简介') {
                this.jumpIntroduction()
            } else if (data == '组织机构') {
                this.jumpOrgan()
            } else if (data == '联系我们') {
                this.jumpConnection()
            }
        },
        jumpIntroduction() {
            this.$router.push('/Instituteintroduction')
        },
        jumpOrgan() {
            this.$router.push('/Instituteorganizational')
        },
        jumpConnection() {
            this.$router.push('/Instituteconnection')
        },
    },
    created() {
        this.getListCount();
    },
}
</script>
  
<style lang="scss" scoped>
.news-box {
    height: auto;
    

    .topTitle {
        position: relative;
        width: 280px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .news-sid {
        margin-top: 1px;
        position: relative;
        width: 281px;
        height: 50px;
        line-height: 50px;
        background-color: #ebf4fb;
    }

    .main {
        height: auto;
        width: 72% !important;
        // height: 100%;
    }

    .bgHover:hover {
        background: #e6e6e6;
        color: rgb(86, 193, 255) !important;
    }

}
</style>
  