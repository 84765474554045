<template>
  <div style="display: flex; justify-content: space-between">
    <!-- <newsTrends
      :modelName="'partyActs'"
      :limit="5"
      style="margin-top: 5px"
    ></newsTrends> -->
    <div
      class="content"
      style="
        width: 100%;
        height: 600px;
        margin-top: 4px;
        overflow: hidden;
        overflow-y: auto;
      "
    >
      <div
        class="content_title"
        style="
          width: width: 100%;
          height: 36px;
          position: relative;
          border-bottom: 1px solid #b9bbbe;
          line-height: 36px;
        "
      >
        <span style="margin-left: 30px; font-weight: 550">搜索结果</span>
        <img
          src="../assets/party/icon1.png"
          style="position: absolute; left: 0; bottom: -2px"
        />
      </div>
      <div
        class="result"
        style="
          width: width: 100%;
          height: 100px;
          margin-top: 10px;
          font-size: 18px;
          position: relative;
          cursor: pointer;
          border-bottom: 1px solid #ccc;
        "
        v-for="(item, index) in searchData"
        :key="index"
        @click="jumpDetail(item.modelName, item.id)"
      >
        <div
          style="
            margin-left: 30px;
            height: 25px;
            line-height: 25px;
            width: 1170px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          "
        >
          {{ item.title }}
        </div>
        <div
          style="
            margin-left: 30px;
            margin-top: 10px;
            width: 1100px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          "
        >
          {{ item.content.replace(/<.*?>/gi, "").split(" ")[0] }}
        </div>
        <div
          style="
            margin-left: 30px;
            margin-top: 10px;
            width: 150px;
            font-size: 14px;
            color: #b9bbbe;
          "
        >
          发布时间 : {{ $moment(item.createdAt).format("YYYY-MM-DD") }}
        </div>
        <img
          src="../assets/point.png"
          style="position: absolute; left: 0; top: 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import newsTrends from "@/view/components/news.vue";
export default {
  data() {
    return {
      searchData: [],
      input: "",
    };
  },
  created() {
    this.searchData = JSON.parse(this.$route.query.searchList);
    this.changeColor(this.searchData);
    this.input = this.$route.query.input;
    // this.calculationParameters(window.location.href);
  },
  components: {
    newsTrends,
  },
  methods: {
    jumpDetail(modelName, id) {
      let routeData = this.$router.resolve({
        path: "/contentDetail",
        query: { model: modelName, id: id },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push(`/contentDetail?model=${modelName}&id=${id}`);
    },
    changeColor(result) {
      //result为接口返回的数据
      result.map((item, index) => {
        if (this.input) {
          /**
           * 使用正则表达式进行全文匹配关键词
           * ig : 表示 全文查找 ,忽略大小写
           *  i : 忽略大小写
           *  g : 全文查找
           *
           * 使用字符串的replace方法进行替换
           * stringObject.replace('被替换的值',替换的值)
              title和name是你要高亮的字段
           */
          let replaceReg = new RegExp(this.input, "ig");
          let replaceString = `<span style="color: #ed4014">${this.input}</span>`;
          result[index].title = item.title.replace(replaceReg, replaceString);
          result[index].content = item.content.replace(
            replaceReg,
            replaceString
          );
        }
      });
      this.searchData = result;
    },
  },
};
</script>
<style>
.content .result:last-child() {
  border-bottom: 0px !important;
}
</style>