import request from "@/utils/request";

export function getAllData() {
    return request({
      url: "/searches/getAllData",
      method: "get",
    //   params: {
    //     filter,
    //   },
    });
  }
  