<template>
  <div class="Pages">
    <el-container style="width: 100vw; height: 100vh">
      <div class="top">
        <div><img src="./static/index/top.png" class="imageTop" /></div>
       
        <div>
          <div style="margin-top: 80px">
            <el-input
              placeholder="请输入内容"
              v-model="input"
              @change="getAll"
              class="input-with-select"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
           
          </div>

         
        </div>
      </div>
      <el-header>
        <el-menu
          router
          :default-active="this.router1"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#ffffff"
          text-color="#000000"
          active-text-color="#4190c8"
        >
          <el-menu-item
            class="menuitemnoChildren"
            v-for="item in noChildren"
            :key="item.name"
            :index="item.name"
          >
            <template slot="title">
              <span class="menuitemfont"> {{ item.navItem }}</span>
            </template>
          </el-menu-item>

          <el-submenu
            class="menuitem"
            v-for="item in hasChildren"
            :key="item.name"
            :index="item.name"
          >
            <template slot="title">
              <span class="menuitemfont"> {{ item.navItem }}</span>
            </template>
            <el-menu-item
              class="menuitem"
              v-for="itemchildren in item.children"
              :key="itemchildren.name"
              :index="itemchildren.name"
            >
              <template slot="title">
                <span class="menuitemfont"> {{ itemchildren.navItem }}</span>
              </template>
            </el-menu-item>
          </el-submenu>

          <!-- <el-menu-item
            class="menuitem"
            v-for="item in noChildren2"
            :key="item.name"
            :index="item.name"
          >
            <template slot="title">
              <span class="menuitemfont"> {{ item.navItem }}</span>
            </template>
          </el-menu-item> -->
        </el-menu>
      </el-header>
      <!-- 内容 -->
      <el-main >
        <router-view  />
        <!-- <div class="Placeholder"></div> -->
      </el-main>
      <el-footer height="auto">
        <div class="footer">
          <img src="./static/index/bottom.png" class="imageBootom" />

          <div style="display: flex">
            <div class="verticalline"></div>

            <div style="margin-right: 24px; margin-left: 25px; display: flex">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 90px;
                  height: 40px;
                  margin-top: 24px;
                  margin-right: 24px;
                "
              >
                <router-link to="/news" target="_blank">
                  <span class="footerfont"> 学会新闻 </span>
                </router-link>
              </div>
              <div
                style="
                  flex-direction: column;
                  width: 90px;
                  height: 40px;
                  margin-top: 24px;
                  margin-right: 24px;
                "
              >
                <div>
                  <router-link to="/partynotification" target="_blank">
                    <span class="footerfont"> 学会动态 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/partynotification" target="_blank">
                    <span class="footerchilren"> 通知公告 </span>
                  </router-link>
                </div>
                <!-- <div class="footerchilren">
                  <router-link to="/partymember" target="_blank">
                    <span class="footerchilren"> 组织成员 </span>
                  </router-link>
                </div> -->
                <div class="footerchilren">
                  <router-link to="/partystate" target="_blank">
                    <span class="footerchilren"> 党建动态 </span>
                  </router-link>
                </div>
              </div>
              <div
                style="
                  flex-direction: column;
                  width: 90px;
                  height: 40px;
                  margin-top: 24px;
                  margin-right: 24px;
                "
              >
                <div>
                  <router-link to="/instituteintroduction" target="_blank">
                    <span class="footerfont"> 关于学会 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/instituteintroduction" target="_blank">
                    <span class="footerchilren"> 学会简介 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/institutearticles" target="_blank">
                    <span class="footerchilren"> 学会章程 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/instituteorganizational" target="_blank">
                    <span class="footerchilren"> 组织机构 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/instituteconnection" target="_blank">
                    <span class="footerchilren"> 联系我们 </span>
                  </router-link>
                </div>
              </div>


              <div
                style="
                  flex-direction: column;
                  width: 122px;
                  height: 40px;
                  margin-top: 24px;
                  margin-right: 24px;
                "
              >
                <div>
                  <router-link to="/studentmathematics" target="_blank">
                    <span class="footerfontda"> 大学生数学竞赛 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/studentmathematics" target="_blank">
                    <span class="footerchilren"> 大学生数学竞赛 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/StudentMathematicscommittee" target="_blank">
                    <span class="footerchilren"> 大学生数学竞赛组委会 </span>
                  </router-link>
                </div>
                
              </div>


              <div
                style="
                  flex-direction: column;
                  width: 160px;
                  height: 40px;
                  margin-top: 24px;
                  margin-right: 24px;
                "
              >
                <div>
                  <router-link to="/studentmodemathematics" target="_blank">
                    <span style="font-family: Microsoft YaHei;
                     color: #000000;
                      font-size: 14px;"> 大学生数学建模竞赛 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/studentmodemathematics" target="_blank">
                    <span class="footerchilren"> 大学生数学建模竞赛 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/studentmodemathematicscommittee" target="_blank">
                    <span class="footerchilren"> 大学生数学建模竞赛组委会 </span>
                  </router-link>
                </div>
                
              </div><div
                style="
                  flex-direction: column;
                  width: 122px;
                  height: 40px;
                  margin-top: 24px;
                  margin-right: 24px;
                "
              >
                <div>
                  <router-link to="/highschoolmath" target="_blank">
                    <span class="footerfontgao"> 高中数学联赛 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/highschoolmath" target="_blank">
                    <span class="footerchilren"> 高中数学联赛 </span>
                  </router-link>
                </div>
                <div class="footerchilren">
                  <router-link to="/highschoolmathcommittee" target="_blank">
                    <span class="footerchilren"> 高中数学联赛组委会 </span>
                  </router-link>
                </div>
                
              </div>

             <!-- <div>
                <router-link to="/studentmathematics" target="_blank">
                  <span class="footerfontda"> 大学生数学竞赛 </span>
                </router-link>
              </div> -->
              <!-- <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 151px;
                  height: 40px;
                  margin-top: 24px;
                  margin-right: 24px;
                "
              >
                <router-link to="/studentmodemathematics" target="_blank">
                  <span class="footerfontdamo"> 大学生数学建模竞赛 </span>
                </router-link>
             

              </div> -->

              <!-- <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 105px;
                  height: 40px;
                  margin-top: 24px;
                  margin-right: 24px;
                "
              >
                <router-link to="/highschoolmath" target="_blank">
                  <span class="footerfontgao"> 高中数学联赛 </span>
                </router-link>
              </div> -->
            </div>

            <div class="verticalline"></div>

            <div
              style="margin-left: 24px; display: flex; flex-direction: column"
            >
              <div
                style="
                  width: 190px;
                  height: 40px;
                  margin-top: 24px;
                  margin-left: 24px;
                "
              >
                <span
                  style="
                    width: 56px;
                    height: 19px;
                    margin: 11px 130px 10px 4px;
                    font-family: Microsoft YaHei;
                    color: #000000;
                    font-size: 14px;
                    text-align: center;
                  "
                >
                  联系我们
                </span>
              </div>
              <div
                style="
                  width: 320px;
                  height: 40px;
                  margin-top: 24px;
                  margin-left: 24px;
                "
              >
                <span
                  style="
                    width: 182px;
                    height: 16px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                    margin-right: 8px;
                    font-family: Microsoft YaHei;
                    color: #000000;
                    font-size: 12px;
                  "
                >
                  纳税人识别号: {{ newsData?.phone }}
                </span>
              </div>
              <div
                style="
                  width: 320px;
                  height: 40px;
                  margin-top: 24px;
                  margin-left: 24px;
                "
              >
                <span
                  style="
                    width: 182px;
                    height: 16px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                    margin-right: 8px;
                    font-family: Microsoft YaHei;
                    color: #000000;
                    font-size: 12px;
                  "
                >
                  邮箱: {{ newsData?.email }}
                </span>
              </div>
              <div
                style="
                  width: 320px;
                  height: 40px;
                  margin-top: 24px;
                  margin-left: 24px;
                "
              >
                <span
                  style="
                    width: 182px;
                    height: 16px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                    margin-right: 8px;
                    font-family: Microsoft YaHei;
                    color: #000000;
                    font-size: 12px;
                  "
                >
                  地址: {{ newsData?.address }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="beianhao">
          <div>
            <a href="https://beian.miit.gov.cn" target="_blank">
                  黔ICP备2023012446号
                </a>
          </div>
          <div style="margin-left: 100px;">
            版权所有：@2023 贵州省数学学会
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { getSocietiy } from '@/api/aboutSociety'
import { createLogger } from 'vuex'
import { getAllData } from './api/home'
export default {
  watch: {
    '$route.path': function (to) {
    }
  },
  created() {
    this.getData()
    // this.init()
    this.router1 = this.$route.path
    getAllData().then((res) => {
      this.GetAllList = res.data.data
    })
  },

  data() {
    return {
      newsData: [],
      closeSearchBox: false,
      GetAllList: [],
      searchList: [],
      router1: '',
      input: '',
      activeIndex: '/indexPage',
      activeIndex2: '/indexPage',
      navList: [
        {
          name: '/indexPage',
          navItem: '首页'
        },
        {
          name: '/news',
          navItem: '学会新闻'
        },
        {
          navItem: '学会动态',
          name: '/partyactivities',
          children: [
            {
              name: '/partynotification',
              navItem: '通知公告'
            },
            // {
            //   name: '/partymember',
            //   navItem: '组织成员'
            // },
            {
              name: '/partystate',
              navItem: '党建动态'
            }
          ]
        },
        {
          name: '/instituteabout',
          navItem: '关于学会',
          children: [
            {
              name: '/instituteintroduction',
              navItem: '学会简介'
            },
            {
              name: '/institutearticles',
              navItem: '学会章程'
            },
            {
              name: '/instituteorganizational',
              navItem: '组织机构'
            },
            // {
            //   name: '/instituteconnection',
            //   navItem: '联系我们'
            // }
          ]
        },
        {
          name: '/studentmathematics',
          navItem: '大学生数学竞赛',
          children: [
            {
              name: '/studentmathematics',
              navItem: '大学生数学竞赛'
            },
            {
              name: '/StudentMathematicscommittee',
              navItem: '大学生数学竞赛组委会'
            }
          ]
        },
        {
          name: '/studentmodemathematics',
          navItem: '大学生数学建模竞赛',
          children: [
            {
              name: '/studentmodemathematics',
              navItem: '大学生数学建模竞赛'
            },
            {
              name: '/StudentModeMathematicscommittee',
              navItem: '大学生数学建模竞赛组委会'
            }
          ]
        },
        {
          name: '/highschoolmath',
          navItem: '高中数学联赛',
          children: [
            {
              name: '/highschoolmath',
              navItem: '高中数学联赛'
            },
            {
              name: '/HighSchoolMathcommittee',
              navItem: '高中数学联赛组委会'
            }
          ]
        }
      ],
      navList2: [
        // {
        //   name: '/studentmathematics',
        //   navItem: '大学生数学竞赛'
        // },
        // {
        //   name: '/studentmodemathematics',
        //   navItem: '大学生数学建模竞赛'
        // },
        {
          name: '/highschoolmath',
          navItem: '高中数学联赛'
        }
      ]
    }
  },

  computed: {
    //没有子菜单
    noChildren() {
      return this.navList.filter((item) => !item.children)
    },
    noChildren2() {
      return this.navList2.filter((item) => !item.children)
    },
    //有子菜单
    hasChildren() {
      return this.navList.filter((item) => item.children)
    }
  },
  methods: {
    getData() {
      getSocietiy({
        where: {
          type: '联系我们'
        },
        order: 'publishTime DESC'
      })
        .then((res) => {
          this.newsData = res.data[0]
        })
        .catch((err) => {})
    },
    gotoDetail(item) {
      this.$router.push(`/contentDetail?model=${item.modename}&id=${item.id}`)
      // this.GetAllList.length = 0
    },
    getAll(words) {
      if (words) {
        this.searchList = this.GetAllList.filter((item) => {
          return item.title.includes(`${words}`)
        })
      } else {
        this.searchList = []
      }
      let routeData = this.$router.resolve({
        path: '/search',
        query: {
          searchList: JSON.stringify(this.searchList),
          input: this.input
        }
      })
      window.window.open(routeData.href, '_blank')
      this.input = ''
    },
    handleSelect(key, keyPath) {
    }
  }
}
</script>

<style lang="scss">
.search_div {
  div {
    // margin-top: 2px;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
body {
  width: 1920px;
  height: 100vh;
  /* overflow: hidden; */
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.Pages {
  /* margin: 0;
    padding: 0; */
  width: 100vw;
  /* overflow: hidden; */
  /* overflow-x: hidden; */
  /* height: 156vh; */
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.top {
  width: 1200px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  background: #ece9d6;
}

.inputTop {
  margin: 80px auto;
  width: 290px !important;
  height: 40px;
}

.imageTop {
  width: 502.38px;
  height: 100px;
  margin-top: 50px;
  margin-right: 367.62px;
  margin-bottom: 50px;
}

.imageBootom {
  width: 114px;
  height: 135.54px;
  margin-top: 106px;
  margin-right: 39px;
}

.menuitem {
  width: 183.8px;
  height: 60px;
  text-align: center;
}
.menuitemnoChildren{
  width: 140.4px;
  height: 60px;
  text-align: center;
}

.menuitemfont {
  text-align: center;
  font-family: Microsoft YaHei;
  font-size: 16px;
  color: #000000;
  width: 72px;
  height: 24px;
}

.el-header {
  display: inline-block;
  margin: 0 auto;
  width: 1200px;
  height: 60px;
  padding: 0 0 0 0px !important;
}



 .el-main  {
  width: 1200px;
  margin: 0 auto;
  overflow:visible !important;
  padding: 0 !important;

}



.el-footer {
  width: 100vw;
  padding: 0 !important;
  background: #e1e1e1;
}

.footer {
  width: 1200px;
  display: flex;
  height: 400px !important;
  padding: 0 0 0 0px !important;
  margin: 0 auto;
}

.verticalline {
  border-left: 1px solid #100f0f;
  /* 这里可以自定义边框的样式 */
  height: 299px;
  /* 这里可以自定义竖线的高度 */
  /* margin-right: 817px; */
  margin-top: 24px;
  margin-bottom: 76px;
  /* 这里可以自定义竖线与其他元素之间的距离 */
}

.footerfont {
  width: 56px;
  height: 19px;
  font-family: Microsoft YaHei;
  color: #000000;
  font-size: 14px;
  margin-top: 11px;
  margin-bottom: 10px;
  margin-right: 34px;
}

.footerfontgao {
  width: 84px;
  height: 19px;
  font-family: Microsoft YaHei;
  color: #000000;
  font-size: 14px;
  margin-top: 11px;
  margin-bottom: 10px;
  margin-right: 21px;
}

.footerfontdamo {
  width: 126px;
  height: 19px;
  font-family: Microsoft YaHei;
  color: #000000;
  font-size: 14px;
  margin-top: 11px;
  margin-bottom: 10px;
  margin-right: 25px;
}

.footerfontda {
  width: 98px;
  height: 19px;
  font-family: Microsoft YaHei;
  color: #000000;
  font-size: 14px;
  margin-top: 11px;
  margin-bottom: 10px;
  margin-right: 24px;
}

// .footerchilren {
//   height: 40px;
//   width: 90px;
// }

.footerchilren {
  margin: 12px 22px 12px 0px;
  width: 166px;
  height: 16px;
  font-family: Microsoft YaHei;
  color: #000000;
  font-size: 12px;
}
.beianhao{
  // text-align: center;
  // padding-bottom: 30px;
  margin-left: 600px;
  margin-bottom: 30px;
  display: flex;
}

.sugg-list {
  padding: 0 5px;

  .sugg-item {
    font-size: 12px;
    padding: 5px 0;
    border-bottom: 1px solid #464343;
    border-left: 1px solid #464343;
    border-right: 1px solid #464343;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #464343;

    .goods-name {
      // 文字不允许换行
      white-space: nowrap;
      // 溢出部分隐藏
      overflow: hidden;
      // 文本溢出后，使用...代替
      text-overflow: ellipsis;
      // margin-right: 3px;
    }
  }
}
.Placeholder{
  height: 451px;
  width: 100vw;
}
.ql-indent-1 {
    padding-left: 0px;
    text-indent: 1em;
}
.ql-indent-2 {
    padding-left: 0px !important;
    text-indent: 2em !important;
}

.ql-indent-3 {
    padding-left: 0px !important;
    text-indent: 3em !important;
}

.ql-indent-4 {
    padding-left: 0px !important;
    text-indent: 4em !important;
}

.ql-indent-5 {
    padding-left: 0px !important;
    text-indent: 5em !important;
}

.ql-indent-6 {
    padding-left: 0px !important;
    text-indent: 6em !important;
}

.ql-indent-7 {
    padding-left: 0px !important;
    text-indent: 7em !important;
}

.ql-indent-8 {
    padding-left: 0px !important;
    text-indent: 8em !important;
}
  .ql-editor {
  overflow-y: hidden !important;
}

</style>
