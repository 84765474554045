<template>
  <div class="home_page">
    <el-carousel height="360px" width="1200px">
      <el-carousel-item v-for="item in carouselList" :key="item.id">
        <img
          :src="baseUrl + item.imgUrl"
          v-if="item.imgUrl"
          style="width: 100%; height: 100%; display: block"
        />
        <img src="../assets/homePage/carouselOne.png" v-else />
      </el-carousel-item>
      <!-- <el-carousel-item>
        <img src="../assets/homePage/carouselOne.png" />
      </el-carousel-item>
      <el-carousel-item>
        <img src="../assets/homePage/carouselOne.png" />
      </el-carousel-item> -->
    </el-carousel>
    <div class="homePage_container">
      <div class="leftTop_container">
        <div class="longTitle">
          <div
            style="
              width: 870px;
              height: 40px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span style="font-size: 20px; font-weight: 700">学会新闻</span>
            <div class="longTitle_icon" @click="checkAll(1)">
              <span>查看全部</span>
              <img src="../assets/homePage/arrow.png" />
            </div>
          </div>
          <img
            src="../assets/homePage/longTitleLine.png"
            style="position: absolute; bottom: 0; left: 0"
          />
        </div>
        <div
          style="width: 870px; display: flex; justify-content: space-between"
        >
          <div
            class="leftTop_photo"
            style="width: 361px; border-radius: 8px 8px 0 0"
          >
            <img
              v-if="topNew[0]?.imgUrl"
              :src="baseUrl + topNew[0]?.imgUrl"
              style="width: 361px; height: 301px"
            />
            <img
              v-else
              src="../assets/homePage/photo.png"
              alt=""
              style="width: 361px; height: 301px; object-fit: scale-down"
            />
            <div
              style="
                height: 50px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                font-size: 18px;
                background-color: #4190c8;
                margin-top: -5px;
                color: white;
                border-radius: 0 0 8px 8px;
              "
              @click="jumpContentDetail('news', topNew[0].id)"
            >
              <span
                style="
                  width: 220px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  height: 50px;
                  line-height: 50px;
                  cursor: pointer;
                "
                >{{ topNew[0]?.title }}</span
              >
              <span style="font-size: 14px">{{
                $moment(topNew[0]?.createdAt).format('YYYY-MM-DD')
              }}</span>
            </div>
          </div>
          <div class="letTop_content" style="width: 490px; height: 350px">
            <div
              style="
                width: 490px;
                height: 40px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
              "
              v-for="(item, index) in newsData"
              :key="index"
              @click="jumpContentDetail('news', item.id)"
            >
              <span
                style="
                  width: 290px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  margin-left: 10px;
                  font-size: 18px;
                "
                >{{ item.title }}</span
              >
              <span style="font-size: 14px; margin-right: 5px; color: #808080">
                {{ $moment(item.createdAt).format('YYYY-MM-DD') }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="rightTop_container">
        <div class="shortTitle">
          <div class="rightTitle">
            <div
              style="
                width: 283px;
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span style="font-size: 20px; font-weight: 700">通知公告</span>
              <div class="shortTitle_icon" @click="checkAll(2)">
                <span>查看全部</span>
                <img src="../assets/homePage/arrow.png" />
              </div>
            </div>
            <img
              src="../assets/homePage/shortTitleLine.png"
              style="position: absolute; bottom: 0; left: 0"
            />
          </div>
          <div class="right_photo">
            <div>
              <img
                v-if="topNotice[0]?.imgUrl"
                :src="baseUrl + topNotice[0]?.imgUrl"
                style="width: 283px; height: 161px"
              />
              <img
                v-else
                src="../assets/homePage/photo.png"
                alt=""
                style="max-height: 161px; transform: translateX(44px)"
              />
              <div
                style="
                  height: 50px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  background-color: #4190c8;
                  margin-top: -5px;
                  color: white;
                  border-radius: 0 0 5px 5px;
                "
                @click="jumpContentDetail('notices', topNotice[0]?.id)"
              >
                <span
                  style="
                    width: 160px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    height: 50px;
                    line-height: 50px;
                    cursor: pointer;
                    font-size: 18px;
                    margin-left: 15px;
                    cursor: pointer;
                  "
                  >{{ topNotice[0]?.title }}</span
                >
                <span style="font-size: 14px; margin-right: 10px">{{
                  $moment(topNotice[0]?.createdAt).format('YYYY-MM-DD')
                }}</span>
              </div>
              <div
                style="
                  height: 40px;
                  margin-bottom: 10px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  cursor: pointer;
                "
                v-for="(item, index) in noticeData"
                :key="index"
                @click="jumpContentDetail('notices', item.id)"
              >
                <span
                  style="
                    width: 160px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 18px;
                  "
                  >{{ item.title }}</span
                >
                <span
                  style="font-size: 14px; margin-right: 5px; color: #808080"
                >
                  {{ $moment(item.createdAt).format('YYYY-MM-DD') }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="homePage_container">
      <div class="leftTop_container">
        <div class="longTitle">
          <div
            style="
              width: 870px;
              height: 40px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span style="font-size: 20px; font-weight: 700">党建动态</span>
            <div class="longTitle_icon" @click="checkAll(3)">
              <span>查看全部</span>
              <img src="../assets/homePage/arrow.png" />
            </div>
          </div>
          <img
            src="../assets/homePage/longTitleLine.png"
            style="position: absolute; bottom: 0; left: 0"
          />
        </div>
        <div
          style="width: 870px; display: flex; justify-content: space-between"
        >
          <div
            class="leftTop_photo"
            style="width: 361px; border-radius: 8px 8px 0 0"
          >
            <img
              v-if="topState[0]?.imgUrl"
              :src="baseUrl + topState[0]?.imgUrl"
              style="width: 361px; height: 301px"
            />
            <img
              v-else
              src="../assets/homePage/photo.png"
              alt=""
              style="width: 361px; height: 301px; object-fit: scale-down"
            />
            <div
              style="
                height: 50px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                font-size: 18px;
                background-color: #4190c8;
                margin-top: -5px;
                color: white;
                border-radius: 0 0 8px 8px;
              "
              @click="jumpContentDetail('states', topState[0]?.id)"
            >
              <span
                style="
                  width: 220px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  height: 50px;
                  line-height: 50px;
                  cursor: pointer;
                "
                >{{ topState[0]?.title }}</span
              >
              <span style="font-size: 14px">{{
                $moment(topState[0]?.createdAt).format('YYYY-MM-DD')
              }}</span>
            </div>
          </div>
          <div class="letTop_content" style="width: 490px; height: 350px">
            <div
              style="
                width: 490px;
                height: 40px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
              "
              v-for="(item, index) in stateData"
              :key="index"
              @click="jumpContentDetail('states', item.id)"
            >
              <span
                style="
                  width: 290px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  margin-left: 10px;
                  font-size: 18px;
                "
                >{{ item.title }}</span
              >
              <span style="font-size: 14px; margin-right: 5px; color: #808080">
                {{ $moment(item.createdAt).format('YYYY-MM-DD') }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="rightTop_container">
        <div class="shortTitle">
          <div class="rightTitle">
            <div
              style="
                width: 280px;
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span style="font-size: 20px; font-weight: 700">学会章程</span>
              <div class="shortTitle_icon" @click="checkAll(4)">
                <span>查看全部</span>
                <img src="../assets/homePage/arrow.png" />
              </div>
            </div>
            <img
              src="../assets/homePage/shortTitleLine.png"
              style="position: absolute; bottom: 0; left: 0"
            />
          </div>
          <div class="right_photo">
            <div>
              <img
                v-if="topRule[0]?.imgUrl"
                :src="baseUrl + topRule[0]?.imgUrl"
                style="width: 283px; height: 161px"
              />
              <img
                v-else
                src="../assets/homePage/photo.png"
                alt=""
                style="max-height: 161px; transform: translateX(44px)"
              />
              <div
                style="
                  height: 50px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  background-color: #4190c8;
                  margin-top: -5px;
                  color: white;
                  border-radius: 0 0 5px 5px;
                "
                @click="jumpContentDetail('learnRules', topRule[0]?.id)"
              >
                <span
                  style="
                    width: 160px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    height: 50px;
                    line-height: 50px;
                    cursor: pointer;
                    font-size: 18px;
                    margin-left: 15px;
                    cursor: pointer;
                  "
                  >{{ topRule[0]?.title }}</span
                >
                <span style="font-size: 14px; margin-right: 10px">{{
                  $moment(topRule[0]?.createdAt).format('YYYY-MM-DD')
                }}</span>
              </div>
              <div
                style="
                  height: 40px;
                  margin-bottom: 10px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  cursor: pointer;
                "
                v-for="(item, index) in rulesData"
                :key="index"
                @click="jumpContentDetail('learnRules', item.id)"
              >
                <span
                  style="
                    width: 160px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 18px;
                  "
                  >{{ item.title }}</span
                >
                <span
                  style="font-size: 14px; margin-right: 5px; color: #808080"
                >
                  {{ $moment(item.createdAt).format('YYYY-MM-DD') }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="publicServe">
      <div class="public" @click="jumpEmail">理事长、秘书长信箱</div>
      <!-- <div class="public" @click="jumpCommunity">社团</div> -->
      <div class="public" @click="jumpCampus">链接单位</div>
    </div>
  </div>
</template>

<script>
import { getSociety } from '../api/partyAct'
import { getNotice, getSocietyRule } from '../api/notice'
import { getState } from '../api/state'
import { getNews } from '@/api/news'
import headBar from '@/components/headBar/headBar'
import { mapMutations } from 'vuex'
import { getCarousel } from '@/api/carousel'
export default {
  name: 'homePage',
  components: {
    headBar
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API + 'containers/file/download/',
      stateData: [],
      noticeData: [],
      topState: [],
      newsData: [],
      rulesData: [],
      topNew: [],
      topNotice: [],
      topRule: [],
      menuIndex: 0,
      loading: false,
      carouselList: []
    }
  },
  created() {
    getCarousel().then((res) => {
      if (res.data.length < 2) {
        this.carouselList = [{}, {}, {}]
      } else {
        this.carouselList = res.data
      }
    })
    this.getNotices()
    this.getState()
    this.getSocietyRules()
    this.getNews()
  },
  methods: {
    ...mapMutations(['setValue']),
    jumpEmail() {
      let routeData = this.$router.resolve({ path: '/email' })
      window.open(routeData.href, '_blank')
    },
    jumpCommunity() {
      let routeData = this.$router.resolve({ path: '/community' })
      window.open(routeData.href, '_blank')
    },
    jumpCampus() {
      let routeData = this.$router.resolve({ path: '/campus' })
      window.open(routeData.href, '_blank')
    },
    getNews() {
      let filter = {
        where: {
          hidden: 1
        },
        limit: 8,
        order: 'createdAt DESC'
      }
      let filter2 = {
        where: {
          hidden: 1,
          state: { neq: 1 }
        },
        limit: 7,
        order: 'createdAt DESC'
      }
      let filter1 = {
        where: {
          hidden: 1,
          state: 1
        },
        order: 'createdAt DESC'
      }
      getNews(filter1).then((topRes) => {
        if (topRes.data.length != 0) {
          this.topNew = topRes.data
          getNews(filter2).then((res) => {
            this.newsData = res.data
          })
        } else {
          getNews(filter).then((res) => {
            this.newsData = res.data
            if (this.topNew.length == 0) {
              this.topNew.push(this.newsData[0])
              this.newsData.shift()
            }
          })
        }
      })
    },
    getNotices() {
      let filter = {
        where: {
          hidden: 1
        },
        limit: 4,
        order: 'createdAt DESC'
      }
      let filter2 = {
        where: {
          hidden: 1,
          state: { neq: 1 }
        },
        limit: 3,
        order: 'createdAt DESC'
      }
      let filter1 = {
        where: {
          hidden: 1,
          state: 1
        },
        order: 'createdAt DESC'
      }
      getNotice(filter1).then((topRes) => {
        if (topRes.data.length != 0) {
          this.topNotice = topRes.data
          getNotice(filter2).then((res) => {
            this.noticeData = res.data
          })
        } else {
          getNotice(filter).then((res) => {
            this.noticeData = res.data
            if (this.topNotice.length == 0) {
              this.topNotice.push(this.noticeData[0])
              this.noticeData.shift()
            }
          })
        }
      })
    },
    getState() {
      let filter = {
        where: {
          hidden: 1
        },
        limit: 8,
        order: 'createdAt DESC'
      }
      let filter2 = {
        where: {
          hidden: 1,
          state: { neq: 1 }
        },
        limit: 7,
        order: 'createdAt DESC'
      }
      let filter1 = {
        where: {
          hidden: 1,
          state: 1
        },
        order: 'createdAt DESC'
      }
      getState(filter1).then((topRes) => {
        if (topRes.data.length != 0) {
          this.topState = topRes.data
          getState(filter2).then((res) => {
            this.stateData = res.data
          })
        } else {
          getState(filter).then((res) => {
            this.stateData = res.data
            if (this.topState.length == 0) {
              this.topState.push(this.stateData[0])
              this.stateData.shift()
            }
          })
        }
      })
    },
    getSocietyRules() {
      let filter = {
        where: {
          hidden: 1
        },
        limit: 4,
        order: 'createdAt DESC'
      }
      let filter2 = {
        where: {
          hidden: 1,
          state: { neq: 1 }
        },
        limit: 3,
        order: 'createdAt DESC'
      }
      let filter1 = {
        where: {
          hidden: 1,
          state: 1
        },
        order: 'createdAt DESC'
      }
      getSocietyRule(filter1).then((topRes) => {
        if (topRes.data.length != 0) {
          this.topRule = topRes.data
          getSocietyRule(filter2).then((res) => {
            this.rulesData = res.data
          })
        } else {
          getSocietyRule(filter).then((res) => {
            this.rulesData = res.data
            if (this.topRule.length == 0) {
              this.topRule.push(this.rulesData[0])
              this.rulesData.shift()
            }
          })
        }
      })
    },
    checkAll(index) {
      if (index == 1) {
        // this.$router.push("/news");
        let routeData = this.$router.resolve({
          path: '/news'
        })
        window.open(routeData.href, '_blank')
      } else if (index == 2) {
        let routeData = this.$router.resolve({
          path: '/partynotification'
        })
        window.open(routeData.href, '_blank')
        // this.$router.push("/partynotification");
      } else if (index == 3) {
        let routeData = this.$router.resolve({
          path: '/partystate'
        })
        window.open(routeData.href, '_blank')
        // this.$router.push("/partynotification");
      } else {
        let routeData = this.$router.resolve({
          path: '/institutearticles'
        })
        window.open(routeData.href, '_blank')
        // this.$router.push("/institutearticles");
      }
    },
    jumpContentDetail(modelName, id) {
      let routeData = this.$router.resolve({
        path: '/contentDetail',
        query: { model: modelName, id: id }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.home_page {
  //   // width: 1200px;
  // height: 200px;
  .publicServe {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 80px;
    .public {
      width: 380px;
      height: 60px;
      background-color: #4190c8;
      color: white;
      font-size: 28px;
      text-align: center;
      line-height: 60px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.homePage_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 390px;
  margin-top: 20px;
  .longTitle {
    position: relative;
    width: 917px;
    height: 40px;
    .longTitle_icon {
      &:hover {
        cursor: pointer;
      }
      span {
        margin-right: 10px;
      }
    }
  }
  .shortTitle {
    position: relative;
    width: 283px;
    height: 40px;
    .shortTitle_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      span {
        margin-right: 10px;
      }
    }
  }
}
::v-deep .el-carousel__indicators--horizontal {
  left: 92%;
}
::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 10px;
}
</style>
