import request from '@/utils/request'

export function getLeaderEmail(params) {
  return request({
    url: '/leaderEmails',
    method: 'get',
    params: {
      filter: params
    }
  })
}

export function getCommunity(params) {
  return request({
    url: '/communities',
    method: 'get',
    params: {
      filter: params
    }
  })
}

export function getCampus(params) {
  return request({
    url: '/campuses',
    method: 'get',
    params: {
      filter: params
    }
  })
}
