import Vue from 'vue'
import Router from 'vue-router'
import indexPage from '@/view/index'
import news from '@/view/News'
import studentmathematics from '@/view/StudentMathematics'
import studentmodemathematics from '@/view/StudentModeMathematics'
import StudentModeMathematicscommittee from '@/view/StudentModeMathematicscommittee'
import StudentMathematicscommittee from '@/view/StudentMathematicscommittee'
import HighSchoolMathcommittee from '@/view/HighSchoolMathcommittee'
import highschoolmath from '@/view/HighSchoolMath'
import instituteabout from '@/view/Instituteabout/Instituteabout'
import instituteintroduction from '@/view/Instituteabout/Instituteintroduction'
import institutearticles from '@/view/Instituteabout/Institutearticles'
import instituteorganizational from '@/view/Instituteabout/Instituteorganizational'
import instituteconnection from '@/view/Instituteabout/Instituteconnection'
import partyactivities from '@/view/Partyactivities/Partyactivities'
import partymember from '@/view/Partyactivities/Partymember'
import partynotification from '@/view/Partyactivities/Partynotification'
import partystate from '@/view/Partyactivities/Partystate'
import contentDetail from '@/view/contentDetail'
import search from '@/view/search'
import email from '@/view/components/email'
import community from '@/view/components/community'
import campus from '@/view/components/campus'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/indexPage',
      name: 'indexPage',
      component: indexPage
    },
    {
      path: '/indexPage',
      name: 'indexPage',
      component: indexPage
    },
    {
      path: '/search',
      name: 'search',
      component: search
    },
    {
      path: '/news',
      name: 'news',
      component: news
    },
    {
      path: '/instituteabout',
      name: 'instituteabout',
      component: instituteabout
    },
    {
      path: '/instituteintroduction',
      name: 'instituteintroduction',
      component: instituteintroduction
    },
    {
      path: '/institutearticles',
      name: 'institutearticles',
      component: institutearticles
    },
    {
      path: '/instituteconnection',
      name: 'instituteconnection',
      component: instituteconnection
    },
    {
      path: '/instituteorganizational',
      name: 'instituteorganizational',
      component: instituteorganizational
    },
    {
      path: '/partyactivities',
      name: 'partyactivities',
      component: partyactivities
    },
    // {
    //   path: '/partymember',
    //   name: 'partymember',
    //   component: partymember
    // },
    {
      path: '/partynotification',
      name: 'partynotification',
      component: partynotification
    },
    {
      path: '/partystate',
      name: 'partystate',
      component: partystate
    },
    {
      path: '/studentmathematics',
      name: 'studentmathematics',
      component: studentmathematics
    },
    {
      path: '/HighSchoolMathcommittee',
      name: 'HighSchoolMathcommittee',
      component: HighSchoolMathcommittee
    },
    {
      path: '/StudentMathematicscommittee',
      name: 'StudentMathematicscommittee',
      component: StudentMathematicscommittee
    },
    {
      path: '/StudentModeMathematicscommittee',
      name: 'StudentModeMathematicscommittee',
      component: StudentModeMathematicscommittee
    },
    {
      path: '/studentmodemathematics',
      name: 'studentmodemathematics',
      component: studentmodemathematics
    },
    {
      path: '/highschoolmath',
      name: 'highschoolmath',
      component: highschoolmath
    },
    {
      path: '/contentDetail',
      name: 'contentDetail',
      component: contentDetail
    },
    {
      path: '/email',
      name: 'email',
      component: email
    },
    {
      path: '/community',
      name: 'community',
      component: community
    },
    {
      path: '/campus',
      name: 'campus',
      component: campus
    }
  ]
})
