<template>
  <div class="news-box">
    <el-container>
      <!-- 头部图片 -->
      <el-header height="214px">
        <div class="headPhoto">
          <img src="../assets/news.png" />
          <div class="head-photo-title">学会新闻</div>
          <div class="head-photo-rect-one"></div>
          <div class="head-photo-rect-two"></div>
        </div>
      </el-header>

      <el-container>
        <!-- 侧边 -->
        <el-aside width="330px">
          <div class="topTitle">
            <span>学会新闻</span>
            <img src="../assets/homePage/shortTitleLine.png"
              style="width: 281px; position: absolute; bottom: 0; left: 0" />
          </div>
          <div class="news-sid">
            <div style="margin-left: 30px">学会新闻</div>
            <img src="../assets/party/icon.png" style="position: absolute; left: 0; bottom: 0" />
          </div>
          <div style="margin-top: 48px">
            <newsTrends :modelName="'news'" :limit="5" :isHidden="true" />
          </div>
        </el-aside>
        <!-- 中间 -->
        <el-main class="main">
          <div style="
              width: 100%;
              height: 1px;
              background: #afafaf;
              position: relative;
              top: 35px;
            "></div>
          <div style="display: flex; align-items: center">
            <div style="height: 34px; width: 18px">
              <div style="
                  height: 25px;
                  width: 18px;
                  background-color: rgba(65, 144, 200, 1);
                "></div>
              <div style="
                  height: 9px;
                  width: 18px;
                  background-color: rgba(89, 194, 224, 1);
                "></div>
            </div>
            <span style="margin-left: 10px; font-weight: 550">学会新闻</span>
            <span style="
                margin-left: 12px;
                color: #e5e5e5;
                font-size: 12px;
                line-height: 26px;
              ">NEWS</span>
          </div>

          <div v-for="(item, index) in newsData" :key="index" style="margin-top: 24px; display: flex; width: 100%">
            <div style="display: flex">
              <div style="
                  margin-top: 16px;
                  margin-bottom: 16px;
                  margin-right: 11px;
                  width: 90%;
                ">
                <div style="width: 10%;height: 47px;margin-right: 14px;margin-left: 8px;
                  ">
                  <span style="font-weight: 700; color: #4190c8; font-size: 36px">{{ item.publishTime | dateformat("DD")
                  }}</span>
                </div>
                <div style="width: 100%; height: 21px; margin-top: 20px">
                  <span style="font-weight: 700; color: #4190c8; font-size: 16px">{{ item.publishTime |
                    dateformat("YYYY-MM") }}</span>
                </div>
              </div>
              <div style="width: 1px; height: 120px; background: #afafaf"></div>
            </div>

            <div style="margin-left: 24px; width: 50%; font-size: 16px">
              <div style="
                  height: 21px;
                  width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  -o-text-overflow: ellipsis;
                ">
                <router-link :to="`/contentDetail?model=${'news'}&id=${item.id}`" target="_blank">
                  <span style="
                      font-family: Microsoft YaHei;
                      color: #4190c8;
                      font-size: 16px;
                    ">
                    {{ item.title }}
                  </span>
                </router-link>
              </div>
              <div style="
                  width: 100%;
                  height: 62px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                ">
                <span>
                    {{ item.abstract }}
                  </span>
              </div>
              <div style="float: right; margin-top: 2px">
                <router-link :to="`/contentDetail?model=${'news'}&id=${item.id}`" target="_blank">
                  <span style="
                      font-family: Microsoft YaHei;
                      color: #4190c8;
                      font-size: 16px;
                    ">
                    立即查看
                  </span>
                </router-link>
              </div>
            </div>
            <div style="margin-left: 47px; width: 33%; height: 120px">
              <img v-if="item.imgUrl" :src="baseUrl + item.imgUrl" alt="" style="width: 282px; height: 120px" />
              <div v-else class="photo">
                <img src="@/assets/news.png" style="width: 282px; height: 120px" />
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <div style="margin-left: 75%;">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize"
        :total="total" background layout="prev, pager, next" style="text-align: center">
      </el-pagination>
    </div>

  </div>
</template>
  
<script>
import { getNews, getNewsCount } from "@/api/news";
import newsTrends from "@/view/components/news";
export default {
  name: "news",
  components: {
    newsTrends,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API + 'containers/file/download/',
      newsData: [],
      modelName: "news",
      total: 0,
      pageSize: 3,
      pageCount: 0,
      currentPage: 1,
    };
  },
  methods: {
    getData(currentPage, pageSize) {
      let params = {
        where: {
          hidden: 1,
        },
        order: "publishTime DESC", //升序
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };
      getNews(params)
        .then((res) => {
          this.newsData = res.data;
          // this.getParam(this.newsData);
        })
        .catch((err) => { });
    },
    getListCount() {
      let params = {
        where: {
          hidden: 1,
        },
      };
      getNewsCount(params)
        .then((response) => {
          this.total = response.data.count;
          this.getData(this.currentPage, this.pageSize);
        })
        .catch((err) => { });
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData(this.currentPage, this.pageSize);
    },

    // getParam(list) {
    //   for (let i = 0; i < list.length; i++) {
    //     // list[i].content.replace(
    //     //   /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
    //     //   (match, capture) => {
    //     //     this.newsData[i] = { ...this.newsData[i], ...{ img: capture } };
    //     //   }
    //     // );
    //     let tpm = list[i].content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g);
    //     let text = tpm.replace("undefined", "");
    //     this.newsData[i] = { ...this.newsData[i], ...{ text: text } };
    //   }
    // },
  },
  created() {
    this.getListCount();
  },
};
</script>
  
<style lang="scss" scoped>
.news-box {
  height: 100%;
  width: 100%;

  .headPhoto {
    position: relative;

    & img {
      width: 1200px;
      height: 200px;
      position: relative;
    }
  }

  .head-photo-title {
    font-size: 36px;
    font-weight: bold;
    position: absolute;
    left: 44.8%;
    top: 38%;
    opacity: 80%;
    color: #ffffff;
  }

  .head-photo-rect-one {
    position: absolute;
    left: 42.5%;
    top: 64.5%;
    opacity: 90%;
    width: 200px;
    height: 2px;
    background: #ffffff;
  }

  .head-photo-rect-two {
    opacity: 90%;
    width: 100px;
    height: 4px;
    background: #ffffff;
    position: absolute;
    left: 46.3%;
    top: 64.5%;
  }

  .topTitle {
    position: relative;
    width: 280px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .news-sid {
    margin-top: 10px;
    position: relative;
    width: 281px;
    height: 50px;
    line-height: 50px;
    background-color: #ebf4fb;
    cursor: pointer;
    border-bottom: 1px solid #b9bbbe;
  }

  .main {
    // height: 100%;
    width: 880px !important;
    height: 550px !important;
    padding: 0 !important;
    margin-left: 0px;
  }
}

// .partyhidden {
//     .headPhoto {
//         & img {
//             width: 1200px;
//             height: 200px;
//         }
//     }

//     .head-photo-title {
//         font-size: 36px;
//         font-weight: bold;
//         position: absolute;
//         left: 47%;
//         top: 36%;
//         opacity: 80%;
//         color: #ffffff;

//     }

//     .head-photo-rect-one {
//         position: absolute;
//         left: 45.5%;
//         top: 41.5%;
//         opacity: 90%;
//         width: 200px;
//         height: 2px;
//         background: #ffffff;

//     }

//     .head-photo-rect-two {
//         opacity: 90%;
//         width: 100px;
//         height: 4px;
//         background: #ffffff;
//         position: absolute;
//         left: 48%;
//         top: 41.3%;
//     }

//     .party_container {
//         width: 1200px;
//         height: 700px;
//         display: flex;
//         justify-content: space-between;

//         .sidebar {
//             width: 281px;

//
//         }
//     }
// }
</style>
  