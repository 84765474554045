import request from '@/utils/request'


export function getState(filter) {//获取数据
    return request({
        url: '/states',
        method: 'get',
        params:{
            filter
        }
    })
}


export function getStateCount() {//获取数据
    return request({
        url: '/states/count',
        method: 'get',
    })
}
