import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		userinfo: {},
		bmi:'',
		wrist:'',
		zlnl:[{DisplayName:'',Gender:'',Age:''}],
		zybj:[{DisplayName:'',Gender:'',Age:''}],
		rzdc:[{DisplayName:'',Gender:'',Age:''}],
		yydc:[{DisplayName:'',Gender:'',Age:''}]
	},
	mutations: {
		//这里是set方法
		setValue(state,data) {
			state[data[0]] = data[1]
		}
	},

	getters: {
		//这里是get方法  
	},
})

export default store
