<template>
  <div class="detail-content">
    <div class="left-content">
      <news :modelName="modelName" :limit="5"></news>
    </div>
    <div class="right-content">
      <!-- <button @click="back">返回上一页</button> -->
      <p class="ql-editor" v-html="articleDetail.content"></p>
      <div v-for="(itemfile, index) in articleDetail.fileList" :key="index">
        <a :href="pathUrl + itemfile.url" :download="itemfile.url">
          <span style="color: #0066cc" v-if="itemfile.url"> 附件： {{ itemfile.name }} </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import news from '../components/news';
export default {
  components: {
    news,
  },
  data() {
    return {
      modelName: '',
      articleId: '',
      articleDetail: '',
      pathUrl: process.env.VUE_APP_BASE_API,
    };
  },
  mounted() {
    //http://localhost:8080/#/contentDetail?model=zxdzxd&id=1231

    this.calculationParameters(window.location.href);
  },
  methods: {
    // ...mapMutations(['setValue']),
    back() {
      window.history.back();
    },
    getdetial() {
      //获取新闻详情
      this.requestMy({
        url: `${this.modelName}/${this.articleId}`, //'/studentmathematics/count',
        method: 'get',
        params: {
          where: {
            state: 0,
            // class: '高中数学联赛',
            hidden: 1,
            // columnId: this.columnId
          },
        },
      })
        .then((res) => {
          this.articleDetail = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    calculationParameters(url) {
      //获取url参数
      let params = [
        url.split('?')[1].split('&')[0].split('=')[1],
        url.split('?')[1].split('&')[1].split('=')[1],
      ];
      this.modelName = params[0];
      this.articleId = params[1];
      this.getdetial();
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-content {
  width: 100%;
  display: flex;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 99%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  -o-tab-size: 4;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  margin: 0;
  word-wrap: break-word;
}

.left-content {
  width: 23%;
}

.right-content {
  width: 72%;
  padding: 32px;
  height: 100%;
}
</style>
