import request from '@/utils/request'


export function getNotice(filter) {//获取数据
    return request({
        url: '/notices',
        method: 'get',
        params:{
            filter
        }
    })
}
export function getSocietyRule(filter) {//获取数据
    return request({
        url: '/learnRules',
        method: 'get',
        params:{
            filter
        }
    })
}


export function getNoticeCount() {//获取数据
    return request({
        url: '/notices/count',
        method: 'get',
    })
}
