import request from "@/utils/request";


export function getSocietiy(params) {
  return request({
    url: "/aboutSocieties",
    method: "get",
    params: {
      filter: params,
    },
  });
}

export function getSocietiyCount(params) {
  return request({
    url: "/aboutSocieties/count",
    method: "get",
    params: params,
  });
}


export function getLearnRules(params) {
  return request({
    url: "/learnRules",
    method: "get",
    params: {
      filter: params,
    },
  });
}

export function getLearnRulesCount(params) {
  return request({
    url: "/learnRules/count",
    method: "get",
    params: params,
  });
}


export function getOrganizations(params) {
  return request({
    url: "/organizations",
    method: "get",
    params: {
      filter: params,
    },
  });
}

export function getOrganizationsCount(params) {
  return request({
    url: "/organizations/count",
    method: "get",
    params: params,
  });
}