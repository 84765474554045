<template>
  <div>
    <div style="margin-top: 10px">
      <div style="height: 294px; width: 282px">
        <div style="width: 140px; height: 26px">
          <span
            style="
              font-family: Microsoft YaHei;
              font-weight: 700;
              color: #000000;
              font-size: 20px;
              margin-top: 14px;
            "
            >最新动态</span
          >
        </div>
        <div style="display: flex">
          <div style="width: 100px; height: 4px; background: #4190c8"></div>
          <div style="width: 182px; height: 1px; background: #4190c8"></div>
        </div>
        <div class="bgHover" style="width: 282px; height: 50px" v-for="(newarticle, index) in articleDetail" :key="index">
          <!-- <div> -->
          <div style="display: flex">
            <div
              style="
                width: 204px;
                height: 21px;
                margin: 15px 22px 14px 22px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              "
            >
            <router-link :to="`/contentDetail?model=${modelName}&id=${newarticle.id}`"  target="_blank" >
              {{ newarticle.title }}
            </router-link>
             
            </div>
            <div style="padding-top: 19px">
              <span class="listTime1">{{ newarticle.publishTime | dateformat('MM-DD') }}</span>
            </div>
          </div>
          <div style="width: 282px; height: 1px; background: #afafaf"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelName: String, //模型名称
    type: String, //类型名称
    limit: Number, //数据数量
    isHidden: Boolean, //数据数量
  },
  data() {
    return {
      articleDetail: '',
      pathUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  watch: {
    modelName(newValue) {
      this.getdetial();
    },
  },
  created(){
    this.getdetial();
  },
  methods: {
    getdetial() {
      //获取新闻
      let reqParams = {
        url: `${this.modelName}`, //'/studentmathematics/count',
        method: 'get',
        params: {
          filter: {
            where: {
              state: 0,
              // class: '高中数学联赛',
              hidden: 1,
              // columnId: this.columnId
            },
            limit: this.limit,
            order: 'createdAt DESC', //升序
          },
        },
      };
      if (!this.isHidden) {
        delete reqParams.params.filter.where.hidden;
      }
      if(this.type){
        reqParams.params.filter.where.type =this.type
      }
      this.requestMy(reqParams)
        .then((res) => {
          this.articleDetail = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bgHover:hover{
  background: #e6e6e6;
}
.bgHover:hover a{
  color: rgb(86, 193, 255) !important;
}
</style>
