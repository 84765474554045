import request from '@/utils/request'


export function getpartyAct(filter) {//获取数据
    return request({
        url: '/partyActs',
        method: 'get',
        params:{
            filter
        }
    })
}


export function getpartyActCount() {//获取数据
    return request({
        url: '/partyActs/count',
        method: 'get',
    })
}

export function getSociety(filter) {//获取数据
    return request({
        url: '/aboutSocieties',
        method: 'get',
        params:{
            filter
        }
    })
}
