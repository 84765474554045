// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import requestMy from './utils/requestMy'
Vue.prototype.requestMy = requestMy
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';

import '@/common/style/theme/index.css';
import '@/style.css'
// import "quill/dist/quill.core.css"
import moment from 'moment'
// Object.defineProperty(Vue.prototype, '$moment', {value: moment})
//全局过滤器  时间戳
Vue.filter('dateformat',function (dataStr,pattern = 'YYYY-MM-DD ') {
  if (dataStr) {
    return moment(dataStr).format(pattern)
  } else {
    return dataStr
  }

})
Vue.config.productionTip = false

Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios
Vue.prototype.$store = store


Vue.use(ElementUI);

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })
new Vue({
  router,
  store,
  render:h=>h(App)
}).$mount('#app')
